import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Card } from 'react-bootstrap-v5'
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { RootState } from '../../../setup'
import { AccreditReports, PdfData } from '../../../types/accredit-reports/AccreditReportsTypes'
import { KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { IAuthState } from '../../modules/auth'
import FilterDropDown from '../../modules/calibration/FilterDropDown'
import * as AccreditReportsRedux from './AccreditReportsRedux'
import { TableColumn } from 'react-data-table-component'
import DataTableBase from '../../components/DataTableBase'
import moment from 'moment'
import {Badge, Dialog, Fab, IconButton } from '@material-ui/core'
import MyDocument from './components/PdfTemplate'
import ReactPDF, { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { GET_REPORTS_API_URL } from './AccreditReportsCrud'
import axios from 'axios'
import { rtrim } from './helper/PdfStylingCheck'
import { Customer } from '../../../types'
import * as levelHelper from '../../../helper/level.helper'
import { ConfirmModal } from '../../components/_modal/ConfirmModal'
import { ActionDropDownNewBalance } from './components/ActionDropDownNewBalance'
import { EditAccreditedReportModal} from './components/EditAccreditedReportModal'
interface ReduxStateValueProps {
  data: AccreditReports[]
  loading: boolean
  total: number
  customerIds: any
  auth: IAuthState & PersistPartial
}

const mapState = (state: any) => ({
  data: state.accreditReports.data,
  loading: state.accreditReports.loading,
  total: state.accreditReports.total,
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, { ...AccreditReportsRedux.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = ReduxStateValueProps

const AccreditReportsPage = (props: Props) => {
  const [perPage, setPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [keyword, setKeyword] = useState<string>('')
  const [from, setFrom] = useState<string>('')
  const [to, setTo] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [item, setItem] = useState<PdfData | undefined>()
  const [load, setLoad] = useState<boolean>(false)
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [isNBSAdmin, setIsNBSAdmin] = useState<boolean>(false)
  const [column, setColumn] = useState('')
  const [sort, setSort] = useState('')
  const [customerIds, setCustomerIds] = useState<string[]>()
  const [editReportNo, setEditReportNo] = useState<number>()
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showEditModule, setShowEditModule] = useState<boolean>(false); 
  const [selectedReport, setSelectedReport] = useState<AccreditReports | null>(null);
  // Extracted action logic as a function
  const handlePDFAction = (row: any) => {
    setLoad(true); // Assuming setLoad is to manage loading state
    axios
      .get(`${GET_REPORTS_API_URL}/getPDFdata`, {
        params: {
          IdShipping: row.IdShipping,
          IdEquipment: row.IdEquipment,
          ReportNo: row.ReportNo,
        },
      })
      .then((res) => {
        // Your logic after fetching data
        openPdfInNewTab(res.data[0])
          .then((res) => {
            // Handle success
          })
          .catch((err) => console.log(err));
        setLoad(false); // Hide loading state
      })
      .catch((error) => {
        // Handle error
        setLoad(false);
        console.log('err==', error);
      });
  };
  const handleDeleteReport = (row: AccreditReports) => {
    setSelectedReport(row); // Set the selected report for deletion
    setShowConfirmModal(true); // Show the confirm modal
  };
  const handleEditReport = (dbkey: number,openOrClose: boolean) => {
    //setSelectedReport(row); // Set the selected report for deletion
    setEditReportNo(dbkey)
    setShowEditModule(openOrClose); // Show the confirm modal
  };

  const handleConfirmDelete = () => {
    if (selectedReport) {
      setLoad(true); // Show loading state
      axios.delete(`${GET_REPORTS_API_URL}/deleteReport`, {
        params: {
          IdShipping: selectedReport.IdShipping,
          IdEquipment: selectedReport.IdEquipment,
          ReportNo: selectedReport.ReportNo,
        },
      })
      .then((res) => {
        // After successful deletion
        console.log('Report deleted successfully');
        // Refresh the table data
        dispatch(AccreditReportsRedux.actions.getAccreditReports({
          page: page,
          perPage: perPage,
          search: keyword,
          from: from,
          to: to,
          customerIds: customerIds,
          column,
          sort,
        }));
      })
      .catch((error) => {
        // Handle error scenario
        console.error('Error deleting report:', error);
      })
      .finally(() => {
        setLoad(false); // Hide loading state
        setShowConfirmModal(false); // Hide the modal
        setSelectedReport(null); // Reset the selected report
      });
    }
  };
  
  const handleCancelDelete = () => {
    setShowConfirmModal(false); // Hide the modal
    setSelectedReport(null); // Reset the selected report
  };

  const dispatch = useDispatch()
  const { data, loading, total, auth } = props
  useEffect(() => {
    dispatch(
      AccreditReportsRedux.actions.getAccreditReports({
        page: page,
        perPage: perPage,
        search: keyword,
        from: from,
        to: to,
        customerIds: customerIds,
        column,
        sort,
      })
    )
  }, [perPage, page, keyword, to, from, customerIds, column, sort])

  useEffect(() => {
    
    if (!levelHelper.isNBS(auth.user?.type)) {
      setCustomerIds(props.customerIds.length > 0 ? props.customerIds : ['nbs'])
    }
  }, [auth])
  useEffect(() => {
   setIsNBS(levelHelper.isNBS(auth.user?.type));
   setIsNBSAdmin(levelHelper.isNBSAdmin(auth.user?.level))
  }, [auth.user?.type]);
  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const activeCustomerIds: string[] = useSelector<RootState>(
    (state) => state.auth.activeCustomerIds
  ) as string[]

  const handleFilter = (filters: any) => {
    const { selectedCustomers, from, to, types, isReset } = filters

    let filterIds: string[] = []
    let filterNames: string[] = []

    if (filterIds.length < 1) {
      activeCustomerIds.forEach((customerId) => {
        filterIds.push(customerId)
      })
    }

    if (!isReset) {
      selectedCustomers.forEach((customer: Customer) => {
        filterIds.push(customer.id)
        filterNames.push(customer.name ?? '')
      })

      setCustomerIds(filterIds)
      setFrom(from)
      setTo(to)
      // setNbsCustomerIds(filterIds)
      // setNbsCustomerNames(filterNames)
    } else {
      setCustomerIds(filterIds)
      setFrom('')
      setTo('')
    }
  }

  const handleSort = (column: any, direction: any) => {
    setColumn(column.id)
    setSort(direction)
  }

  const openPdfInNewTab = async (data: any) => {
    try {
      // setLoad(true) // Show loading state
      if (data) {
        const pdfBlob = await ReactPDF.pdf(<MyDocument data={data} />).toBlob()
        const pdfUrl = URL.createObjectURL(pdfBlob)

        window.open(pdfUrl, '_blank') // Open PDF in new tab

        setLoad(false) // Hide loading state
      }
    } catch (error) {
      console.error('Error generating PDF:', error)
      setLoad(false)
    }
  }
  const columns: TableColumn<AccreditReports>[] = useMemo(
    () => [
      {
        name: 'Actions',
        cell: (row) => (
          <Badge
            variant='standard'
            badgeContent={1}
            color='secondary'
          >
       <ActionDropDownNewBalance
              isNBS={isNBS}
              isNBSAdmin={isNBSAdmin}
              data={row}
              handlePDFAction={handlePDFAction}
              handleDeleteReport={handleDeleteReport}
              handleAttachmentModal={1}
              handleEdit={handleEditReport}
            />
          </Badge>
        ),
        witdth: '5%',
        style: {
          flexGrow: 1,
        },
      },
      {
        name: 'ReportNo',
        id: 'ReportNo',
        cell: (row) => row.ReportNo,
        selector: (row) => row.ReportNo,
        sortable: true,
        witdth: '11%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'CustomerID',
        name: 'CustomerID',
        cell: (row) => row.customers.CustomerID,
        selector: (row) => row.customers.CustomerID,
        sortable: true,
        witdth: '8%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'SerialNumber',
        name: 'SerialNo',
        cell: (row) => row.SerialNumber,
        selector: (row) => row.SerialNumber,
        sortable: true,
        witdth: '12%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'UnitID',
        name: 'Unit ID',
        cell: (row) => row.UnitID,
        selector: (row) => row.UnitID,
        sortable: true,
        witdth: '10%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'Mfg',
        name: 'Manufacturer',
        cell: (row) => row.Mfg,
        selector: (row) => row.Mfg,
        sortable: true,
        witdth: '9%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'Model',
        name: 'Model',
        cell: (row) => row.Model,
        selector: (row) => row.Model,
        sortable: true,
        witdth: '11%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'Department',
        name: 'Department',
        cell: (row) => row.Department,
        selector: (row) => row.Department,
        sortable: true,
        witdth: '10%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'Location',
        name: 'Location',
        cell: (row) => row.Location,
        selector: (row) => row.Location,
        sortable: true,
        witdth: '10%',
        style: {
          flexGrow: 1,
        },
      },
      {
        id: 'date',
        name: 'Date Tested',
        cell: (row) => (
          <span>
            {moment(`${row.CalibrationYear}-${row.CalibrationMonth}-${row.CalibrationDate}`).format(
              'MM/DD/YYYY'
            )}
          </span>
        ),
        selector: (row) => `${row.CalibrationYear}-${row.CalibrationMonth}-${row.CalibrationDate}`,
        sortable: true,
        witdth: '11%',
        style: {
          flexGrow: 1,
        },
      },
    ],
    [isNBS, isNBSAdmin]
  )
  return (
    <>
      <PageTitle breadcrumbs={[]}>Balance & Scale Reports</PageTitle>
      {showConfirmModal && (
        <ConfirmModal
          content="Are you sure you want to delete this report?"
          yesText="Yes, delete it!"
          noText="No, go back"
          selectYes={handleConfirmDelete}
          selectNo={handleCancelDelete}
        />
      )}
      {showEditModule && (
        <EditAccreditedReportModal
        data={1}
        ReportNumber={editReportNo || 0}
        open={true}
        handleEditReport={handleEditReport}
        pdfAction={handlePDFAction}
        />
      )}
      <Card className='min-vh-100'>
        <Dialog open={load}>
          <img src='/media/pdf/pdf-loading.gif' />
        </Dialog>{' '}
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Calibration Reports'
                value={keyword}
                onChange={(e: any) => setKeyword(e.target.value)}
              />
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <div className='filter-dropdown ms-3'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown
                  handleFilter={handleFilter}
                  handleCancelCustomer={handleCancelCustomer}
                  accredited
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 calibration-reports-body'>
          <DataTableBase
            columns={columns}
            data={data}
            pagination
            onSort={handleSort}
            paginationTotalRows={total}
            paginationPerPage={perPage}
            progressPending={loading}
            paginationRowsPerPageOptions={[10, 15, 20, 25, 50,100,200]}
            paginationDefaultPage={1}
            paginationServer
            sortServer
            onChangePage={(m, n) => {
              setPage(m)
            }}
            onChangeRowsPerPage={(e, n) => {
              setPerPage(e)
            }}
          />
        </Card.Body>
      </Card>
      {/* <Dialog
        open={open}
        fullScreen
        onClose={() => {
          setOpen(false)
          setItem(undefined)
        }}
      >
        <Fab
          variant='circular'
          size='small'
          onClick={() => {
            setOpen(false)
            setItem(undefined)
          }}
          style={{ position: 'fixed', right: '60px', top: '80px' }}
        >
          <KTSVG
            path='/media/icons/duotone/Interface/Close-Square.svg'
            className='svg-icon-2 svg-icon-dark-500'
          />
        </Fab> */}
      {/* <PDFViewer

      // style={{ width: '100%', height: '100%' }}
      >
        {item && <MyDocument data={item} />}
      </PDFViewer> */}
      {/* {item && (
        <PDFDownloadLink document={<MyDocument data={item} />} fileName='test.pdf'>
          {({ blob, url, loading, error }) => (loading ? 'Loading document' : 'Download Now')}
        </PDFDownloadLink>
      )} */}
      {/* </Dialog> */}
    </>
  )
}

export default connector(AccreditReportsPage)
