import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { QuoteModel } from '../../../types'
import { getServiceQuotes } from './serviceQuotesCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  ServiceQuotesRequested: '[Request Service Quotes] Action',
  ServiceQuotesLoaded: '[Load Service Quotes] Action',
  SetQuoteNumberSlug: '[Set Quote Number Slug on Service Quotes]',
}

const initialQuotesState: IServiceQuotesState = {
  loading: false,
  serviceQuotes: [],
  quoteNumberSlug: null,
  totals: 0,
}

export interface IServiceQuotesState {
  loading: boolean
  serviceQuotes: any
  quoteNumberSlug: any
  totals: number
}

export const reducer = (
  state: IServiceQuotesState = initialQuotesState,
  action: ActionWithPayload<IServiceQuotesState>
) => {
  switch (action.type) {
    case actionTypes.ServiceQuotesRequested: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.ServiceQuotesLoaded: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    }

    case actionTypes.SetQuoteNumberSlug: {
      const quoteNumberSlug = action.payload?.quoteNumberSlug
      return {
        ...state,
        quoteNumberSlug,
      }
    }

    default:
      return state
  }
}

export const actions = {
  getServiceQuotes: (searchFilter: any = {}) => ({
    type: actionTypes.ServiceQuotesRequested,
    payload: { searchFilter },
  }),

  serviceQuotesLoaded: (serviceQuotes: QuoteModel[], totals: number) => ({
    type: actionTypes.ServiceQuotesLoaded,
    payload: { serviceQuotes, totals },
  }),

  setQuoteNumberSlug: (quoteNumberSlug: any) => ({
    type: actionTypes.SetQuoteNumberSlug,
    payload: { quoteNumberSlug },
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.ServiceQuotesRequested,
    function* serviceQuotesRequested(action: any) {
      const { searchFilter } = action.payload
      const {
        data: { serviceQuotes, totals },
      } = yield getServiceQuotes(searchFilter)

      yield put(actions.serviceQuotesLoaded(serviceQuotes, totals))
    }
  )
}
