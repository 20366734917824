import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { Formik, Form, Field } from 'formik'
import { SelectDropDown } from '../../../components/SelectDropDown'
import { STATES } from '../../../../data/constants'
import { CustomDropDown } from './CustomDropDown'
import { BillingCompany, CustomerModel, NBSCustomer } from '../../../../types'
import { ConfirmCustomerModal } from './ConfirmCustomerModal'
import { checkIsCustomerExist } from '../../../services/service'
import {
  getBillingCompanies,
  getBillingCompanyWithId,
} from '../../billing-companies/BillingCompanyCRUD'
import { Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core'
import DatePicker from 'react-datepicker'
import ReactQuill from 'react-quill'
import { getBillingCompanyID } from '../../billing-companies/BillingCompanyCRUD'
import { BillingCompanyModal } from '../../billing-companies/component/BillingCompanyModal'
type Props = {
  show: boolean
  onHide: any
  userId: number | undefined
  getCustomers: () => void
  idShipping: string
  customerID: string
  billingCompanies: BillingCompany[]
  fetchBillingCompanies : () => void
}

const CustomerEditSchema = Yup.object().shape({
  customerId: Yup.string()
    .required('Customer ID is required')
    .length(6, 'Customer ID must be exactly 6 characters long'),
  companyId: Yup.string().required('Company ID is required'),
})

const AddCustomerModal: React.FC<Props> = (props) => {
  const { show, onHide, getCustomers, idShipping, billingCompanies, customerID } = props
  const initialBillingCompany = useMemo(() => {
    return {
      CompanyName: '',
      BillingCompanyID: '',
      BillAttn_email: '',
      BillPhone: '',
      BillFax: '',
      BillAddress: '',
      BillAddress2: '',
      BillCity: '',
      BillState: '',
      BillZip: '',
      BillNotes: '',
      taxExempt: 'No',
      netTerms: 30,
      expirationDate: '',
    }
  }, [])
  const initialValues = useMemo(() => {
    return {
      customerId: customerID,
      companyId: '',
      shipCompanyName: '',
      taxExempt: 'No',
      status: 'active',
      contactFirstName: '',
      contactLastName: '',
      shipPhoneNumber: '',
      shipFaxNumber: '',
      shipAddress: '',
      shipAddress2: '',
      shipCity: '',
      shipState: '',
      shipZip: '',
      shipNotes: '',
      billingCompanyName: '',
      billContact: '',
      billPhoneNumber: '',
      billFaxNumber: '',
      billAddress: '',
      billAddress2: '',
      billCity: '',
      billState: '',
      billZip: '',
      // billNotes: '',
      netTerms: 30,
      email: '',
      idShipping: '',
      ynFaxEmailOK: false,
      ynMailOK: false,
      CalContact: '',
      CalFreqComment: '',
      DateLastPrinted: null,
      DateLastSale: null,
    }
  }, [customerID])

  const [shipToggle, setShipToggle] = useState<boolean>(false)
  const [billToggle, setBillToggle] = useState<boolean>(false)
  const [billState, setBillState] = useState<string>('')
  const [newCustomer, setNewCustomer] = useState<NBSCustomer>(initialValues)
  const [showView, setShowView] = useState<boolean>(false)
  const [isExist, setIsExist] = useState<boolean>(false)
  // const [cidShipping, setIDShipping] = useState<string>('')
  const [companyIds, setCompanyIds] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(false)
  const quillRef = useRef<ReactQuill>(null)
  const [isShowBillingCompanyModal, setIsShowBillingCompanyModal] = useState(false);
  const [currentBillingCompany, setCurrentBillingCompany] =
  useState<BillingCompany>(initialBillingCompany)
  const [tempBillingId, setTempBillingId] = useState<string>('');
  // Function to open the modal and fetch data 
  const openBillingCompanyModal = () => {
    getBillingCompanyID()
      .then((res) => {
        console.log(res.data)
        setCurrentBillingCompany({
          ...initialBillingCompany,
          BillingCompanyID: res.data,// Merge fetched data with initial data
        });
        console.log(currentBillingCompany)
        setIsShowBillingCompanyModal(true); // Open the modal
      })
      .catch((err) => {
        console.error(err);
        // Handle error (e.g., show notification)a
      });
  };

  const handleChangeCompanyId = (BillingCompanyID: string, setFieldValue: any) => {
    setLoading(true)
    getBillingCompanyWithId(BillingCompanyID)
      .then((res) => {
        setTempBillingId(BillingCompanyID)
        setFieldValue('billingCompanyName', res.data.CompanyName)
        setFieldValue('billPhoneNumber', res.data.BillPhone)
        setFieldValue('billFaxNumber', res.data.BillFax)
        setFieldValue('billAddress', res.data.BillAddress)
        setFieldValue('billAddress2', res.data.BillAddress2)
        setFieldValue('billCity', res.data.BillCity)
        setFieldValue('billZip', res.data.BillZip)
        setFieldValue('billContact', res.data.BillAttn_email)
        setFieldValue('billState', res.data.BillState)
        // setFieldValue('billNotes', res.data.BillNotes)

        setBillState(res.data.BillState)
        setLoading(false)
        if (check) {
          setFieldValue('shipCompanyName', res.data.CompanyName)
          // setFieldValue('contactFirstName', values.billContact)
          // setFieldValue('contactLastName', values.billContact)
          setFieldValue('shipPhoneNumber', res.data.BillPhone)
          setFieldValue('shipFaxNumber', res.data.BillFax)
          setFieldValue('shipAddress', res.data.BillAddress)
          setFieldValue('shipAddress2', res.data.BillAddress2)
          setFieldValue('shipCity', res.data.BillCity)
          setFieldValue('shipState', res.data.BillState)
          setFieldValue('shipZip', res.data.BillZip)
          // setFieldValue('shipNotes', res.data.BillNotes)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const handleSubmit = (values: NBSCustomer) => {
    checkIsCustomerExist(values.customerId).then((res) => {
      if (res.data.message === 'exist') {
        setIsExist(true)
      } else {
        setIsExist(false)
        values.idShipping = idShipping
        setNewCustomer(values)
        setShowView(true)
      }
    })
  }

  const handleHide = () => {
    setShowView(false)
  }

  const onSubmit = () => {
    setShowView(false)
    onHide()
    getCustomers()
  }

  useEffect(() => {
    setBillState(initialValues.billState)
  }, [initialValues])

  // Get company Ids from billing
  useEffect(() => {
    // getBillingCompanies().then(({ data }) => {
    // setBillingCompanies(data)
    setCompanyIds(
      billingCompanies.map(({ BillingCompanyID, CompanyName }: BillingCompany) => ({
        code: BillingCompanyID,
        name: `${BillingCompanyID} - ${CompanyName}`,
      }))
    )
    initialValues.companyId = tempBillingId
    // })
  }, [billingCompanies])
  const handleCloseBillingCompanyModal = useCallback(() => {
    // Closes the billing modal
    setIsShowBillingCompanyModal(false);
   }, []);
  return (
    <>
      <Modal
        size='lg'
        show={show}
        aria-labelledby='contained-modal-title-vcenter'
        className={showView ? 'visually-hidden' : ''}
        onHide={() => {
          setCheck(false)
          onHide(true)
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
            <div className='d-flex justify-content-between'>
              <h2>Add Customer</h2>
              <span
                className='text-hover-primary cursor-pointer'
                onClick={() => {
                  setCheck(false)
                  onHide(true)
                }}
              >
                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-10 pb-15 px-lg-17'>
          <Formik
            validationSchema={CustomerEditSchema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            {({ handleSubmit, setFieldValue, errors, touched, values }) => {
              const handleCheck = (e: any) => {
                setCheck(e.target.checked)
                if (e.target.checked) {
                  setFieldValue('shipCompanyName', values.billingCompanyName)
                  // setFieldValue('contactFirstName', values.billContact)
                  // setFieldValue('contactLastName', values.billContact)
                  setFieldValue('shipPhoneNumber', values.billPhoneNumber)
                  setFieldValue('shipFaxNumber', values.billFaxNumber)
                  setFieldValue('shipAddress', values.billAddress)
                  setFieldValue('shipAddress2', values.billAddress2)
                  setFieldValue('shipCity', values.billCity)
                  setFieldValue('shipState', values.billState)
                  setFieldValue('shipZip', values.billZip)
                  // setFieldValue('shipNotes', values.billNotes)
                } else {
                  setFieldValue('shipCompanyName', '')
                  // setFieldValue('contactFirstName', '')
                  // setFieldValue('contactLastName', '')
                  setFieldValue('shipPhoneNumber', '')
                  setFieldValue('shipFaxNumber', '')
                  setFieldValue('shipAddress', '')
                  setFieldValue('shipAddress2', '')
                  setFieldValue('shipCity', '')
                  setFieldValue('shipState', '')
                  setFieldValue('shipZip', '')
                  setFieldValue('shipNotes', '')
                }
              }
              // useEffect(() => {
              // }, [check, setFieldValue, values])
              return (
                <Form>
                  <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
                    <div className='d-flex flex-row align-items-center justify-content-between fv-plugins-icon-container'>
                      <div className='col-lg-5 mb-7'>
                        <label className='fw-bold fs-6 required mb-2'>Customer ID</label>
                        <Field
                          as='input'
                          name='customerId'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.customerId && errors.customerId ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.customerId}</div>
                          </div>
                        ) : (
                          isExist && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>Customer ID is already existing.</div>
                            </div>
                          )
                        )}
                      </div>
                      <div className='col-lg-5 mb-7'>
                        <h6>Customer Shipping ID: {idShipping}</h6>
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 required mb-2'>
                          Assigned Billing Company ID
                        </label>
                        <CustomDropDown
                          data={companyIds}
                          defaultValue={initialValues.companyId}
                          dirUp={false}
                          showButton={true}
                          selectedValue={(code: string) => {
                            setFieldValue('companyId', code)
                            handleChangeCompanyId(code, setFieldValue)
                          }}
                          isSearchable={true}
                          onShowButtonClick={openBillingCompanyModal}
                        />
                      <BillingCompanyModal
                        isShow={isShowBillingCompanyModal}
                        onHide={handleCloseBillingCompanyModal}
                        billingCompany={currentBillingCompany} // Pass the updated state
                        billingCompanies={[]}
                        getBillingCompanies={() => { throw new Error('Function not implemented.'); }}
                        isCommingFromCustomers={true}
                        fetchBillingCompanies={props.fetchBillingCompanies}
                        handleChangeCompanyId={(companyId) => handleChangeCompanyId(companyId, setFieldValue)}
                        setCustomerFieldValue={setFieldValue}
                      />
                        <Field
                          as='input'
                          name='companyId'
                          className='form-control form-control-solid d-none'
                        />
                        {loading && <LinearProgress />}
                        {touched.companyId && errors.companyId ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.companyId}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row fv-plugins-icon-container'>
                      {/* <div className='col-lg-4 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Net Terms</label>
                        <SelectDropDown
                          data={[30, 35, 45, 60, 90]}
                          defaultValue={initialValues.netTerms}
                          dirUp={false}
                          selectedValue={(value: string | number) =>
                            setFieldValue('netTerms', value)
                          }
                        />
                        <Field
                          as='input'
                          name='netTerms'
                          className='form-control form-control-solid d-none'
                        />
                        {touched.status && errors.status ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.netTerms}</div>
                          </div>
                        ) : null}
                      </div> */}
                      {/* <div className='col-lg-4 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Tax Exempt</label>
                        <SelectDropDown
                          data={['Yes', 'No']}
                          defaultValue={initialValues.taxExempt}
                          dirUp={false}
                          selectedValue={(value: string | number) =>
                            setFieldValue('taxExempt', value)
                          }
                        />
                        <Field
                          as='input'
                          name='taxExempt'
                          className='form-control form-control-solid mb-3 d-none'
                        />
                      </div> */}
                      <div className='col-lg-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Active</label>
                        <SelectDropDown
                          data={['active', 'inactive']}
                          defaultValue={initialValues.status}
                          dirUp={false}
                          selectedValue={(value: string | number) => setFieldValue('status', value)}
                        />
                        <Field
                          as='input'
                          name='status'
                          className='form-control form-control-solid d-none'
                        />
                        {touched.status && errors.status ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.status}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='col-lg-5 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Cal Contact</label>
                        <Field
                          as='input'
                          name='CalContact'
                          className='form-control form-control-solid mb-6 mb-lg-0'
                        />
                      </div>
                      <div className='col-lg-7 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Cal Comment</label>
                        <Field
                          as='input'
                          name='CalFreqComment'
                          className='form-control form-control-solid mb-6 mb-lg-0'
                        />
                      </div>
                      {/* <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Date Last Printed</label>
                        <DatePicker
                          className='form-control'
                          placeholderText='Select a date'
                          selected={
                            values.DateLastPrinted ? new Date(values.DateLastPrinted) : null
                          }
                          dateFormat='MM/dd/yy hh:mma'
                          showTimeSelect
                          onChange={(date) => {
                            setFieldValue('DateLastPrinted', date)
                          }}
                        />
                      </div>
                      <div className='col-lg-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>Date of Last Sale</label>
                        <DatePicker
                          className='form-control'
                          placeholderText='Select a date'
                          selected={values.DateLastSale ? new Date(values.DateLastSale) : null}
                          dateFormat='MM/dd/yy hh:mma'
                          showTimeSelect
                          onChange={(date) => {
                            setFieldValue('DateLastSale', date)
                          }}
                        />
                      </div> */}
                      <div className='col-lg-6 mb-7 d-flex'>
                        <label className='fw-bold fs-6'>Fax/Email OK </label>
                        <Field type='checkbox' name='ynFaxEmailOK' style={{ width: '100px' }} />
                      </div>
                      <div className='col-lg-6 mb-7 d-flex'>
                        <label className='fw-bold fs-6'>Mail OK</label>
                        <Field type='checkbox' name='ynMailOK' style={{ width: '100px' }} />
                      </div>
                      <div className='col-lg-8 mb-7'>
                        <label className='fw-bold fs-6 required mb-2'>Email Address</label>
                        <Field
                          as='input'
                          name='email'
                          className='form-control form-control-solid mb-3 mb-lg-0'
                        />
                        {touched.email && errors.email ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.email}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='d-flex mb-5 cursor-pointer align-items-center'>
                      <div className='d-flex' onClick={() => setShipToggle(!shipToggle)}>
                        <h3>Shipping Information</h3>
                        <KTSVG
                          path={`/media/icons/duotone/Navigation/${
                            shipToggle ? 'Angle-up' : 'Angle-down'
                          }.svg`}
                          className='svg-icon-1'
                        />
                      </div>
                      <FormControlLabel
                        className='ms-6'
                        control={<Checkbox color='primary' onChange={handleCheck} value={check} />}
                        label='Load from Billing Information'
                      />
                    </div>
                    <div className={`${shipToggle ? 'd-none' : 'd-block'} ps-5`}>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Ship Company Name</label>
                          <Field
                            as='input'
                            name='shipCompanyName'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                        </div>
                        {touched.shipCompanyName && errors.shipCompanyName ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.shipCompanyName}</div>
                          </div>
                        ) : null}
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Contact First Name</label>
                          <Field
                            as='input'
                            name='contactFirstName'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.contactFirstName && errors.contactFirstName ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.contactFirstName}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Contact Last Name</label>
                          <Field
                            as='input'
                            name='contactLastName'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.contactLastName && errors.contactLastName ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.contactLastName}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Phone Number</label>
                          <Field
                            as='input'
                            name='shipPhoneNumber'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.shipPhoneNumber && errors.shipPhoneNumber ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.shipPhoneNumber}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                          <Field
                            as='input'
                            name='shipFaxNumber'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.shipFaxNumber && errors.shipFaxNumber ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.shipFaxNumber}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Address Line1</label>
                          <Field
                            as='input'
                            name='shipAddress'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.shipAddress && errors.shipAddress ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.shipAddress}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Address Line2</label>
                          <Field
                            as='input'
                            name='shipAddress2'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.shipAddress && errors.shipAddress ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.shipAddress}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>City</label>
                          <Field
                            as='input'
                            name='shipCity'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.shipCity && errors.shipCity ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.shipCity}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>State</label>
                          <CustomDropDown
                            data={STATES}
                            defaultValue={values.shipState}
                            dirUp={false}
                            selectedValue={(code: string) => setFieldValue('shipState', code)}
                          />
                          <Field
                            as='input'
                            name='shipState'
                            className='form-control form-control-solid d-none'
                          />
                          {touched.shipState && errors.shipState ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.shipState}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                          <Field
                            as='input'
                            name='shipZip'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                          />
                          {touched.shipZip && errors.shipZip ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.shipZip}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className='col-lg-12 mb-5'>
                          <label className='fw-bold fs-6 mb-2'>Shipping Notes</label>
                          <ReactQuill
                            ref={quillRef}
                            theme='snow'
                            defaultValue={values.shipNotes}
                            className='quillView'
                            onChange={(
                              content: string,
                              delta: any,
                              source: string,
                              editor: any
                            ): void => {
                              const plainText = editor.getText(content)
                              if (plainText.length <= 1000) {
                                setFieldValue('shipNotes', plainText)
                              } else {
                                if (quillRef !== null) {
                                  quillRef.current?.getEditor().setText(plainText.slice(0, 1000))
                                }
                                setFieldValue('shipNotes', plainText.slice(0, 1000))
                              }
                            }}
                          />
                          <Field
                            as='input'
                            name='shipNotes'
                            modules={{
                              toolbar: {
                                container: '#toolbar-container',
                              },
                            }}
                            className='form-control form-control-solid mb-3 d-none'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex mb-5 cursor-pointer'>
                      <div className='d-flex' onClick={() => setBillToggle(!billToggle)}>
                        <h3>Billing Information</h3>
                        <KTSVG
                          path={`/media/icons/duotone/Navigation/${
                            billToggle ? 'Angle-up' : 'Angle-down'
                          }.svg`}
                          className='svg-icon-1'
                        />
                      </div>
                    </div>
                    <div className={`${billToggle ? 'd-none' : 'd-block'} ps-5`}>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Company Name</label>
                          <Field
                            as='input'
                            name='billingCompanyName'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billingCompanyName && errors.billingCompanyName ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billingCompanyName}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Contact Name</label>
                          <Field
                            as='input'
                            name='billContact'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billContact && errors.billContact ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billContact}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Phone Number</label>
                          <Field
                            as='input'
                            name='billPhoneNumber'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billPhoneNumber && errors.billPhoneNumber ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billPhoneNumber}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                          <Field
                            as='input'
                            name='billFaxNumber'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billFaxNumber && errors.billFaxNumber ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billFaxNumber}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Address Line1</label>
                          <Field
                            as='input'
                            name='billAddress'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billAddress && errors.billAddress ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billAddress}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Address Line2</label>
                          <Field
                            as='input'
                            name='billAddress2'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billAddress && errors.billAddress ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billAddress}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>City</label>
                          <Field
                            as='input'
                            name='billCity'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billCity && errors.billCity ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billCity}</div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='row fv-plugins-icon-container'>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>State</label>
                          <CustomDropDown
                            data={STATES}
                            defaultValue={billState}
                            dirUp={true}
                            selectedValue={(code: string) => setFieldValue('billState', code)}
                            disable={true}
                          />
                          <Field
                            as='input'
                            name='billState'
                            className='form-control form-control-solid d-none'
                          />
                          {touched.billState && errors.billState ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billState}</div>
                            </div>
                          ) : null}
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                          <Field
                            as='input'
                            name='billZip'
                            className='form-control form-control-solid mb-3 mb-lg-0'
                            readOnly={true}
                          />
                          {touched.billZip && errors.billZip ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.billZip}</div>
                            </div>
                          ) : null}
                        </div>
                        {/* <div className='col-lg-12 mb-5'>
                          <label className='fw-bold fs-6 mb-2'>Billing Notes</label>
                          <ReactQuill
                            theme='snow'
                            className='quillView'
                            defaultValue={values.billNotes}
                            onChange={(value) => setFieldValue('billNotes', value)}
                          />
                          <Field
                            as='input'
                            name='billNotes'
                            modules={{
                              toolbar: {
                                container: '#toolbar-container',
                              },
                            }}
                            className='form-control form-control-solid mb-3 d-none'
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className='text-center pt-15'>
                    <button
                      type='button'
                      className='btn btn-light me-3'
                      onClick={() => onHide(true)}
                    >
                      Discard
                    </button>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      <ConfirmCustomerModal
        type='add'
        data={newCustomer}
        customerKey={0}
        show={showView}
        onHide={handleHide}
        submit={onSubmit}
      />
    </>
  )
}

export { AddCustomerModal }
