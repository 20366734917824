/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Divider, Typography } from '@material-ui/core'
import { CUSTOMER_STATUS, NBS_ADMIN_LEVEL } from '../../../../../data/constants'
import { NBSAdminsTableProps, UserModel } from '../../../../../types'
import { KTSVG } from '../../../../../_metronic/helpers'
import { getTimeInCentralTime } from '../../../../../helper/time.helper'
import { checkIfImageExists } from '../../../../../helper'
import { SendNotification } from '../../../../components/_modal/SendNotification'
import MultiSelect from './MultiSelect'
import Table from './Table'
import FilterDropDown, { FilterDropdownInitialStatus } from '../../../../modules/customers/components/FilterDropDown'
import { connect, ConnectedProps } from 'react-redux'
import * as customersRedux from '../../../customer/redux/customerRedux'
import * as nbsAdminsRedux from '../../../nbs-admin/redux/nbsAdminRedux'
import { RootState } from '../../../../../setup'

import * as levelHelper from '../../../../../helper'

const mapState = (state: RootState) => ({
  customers: state.customers.customers,
  nbsAdmins: state.nbsAdmins.nbsAdmins,
  loading: state.customers.loading,
  auth: state.auth,
})

const connector = connect(mapState, {
  ...customersRedux.actions,
  ...nbsAdminsRedux.actions,
})

type PropsFromRedux = ConnectedProps<typeof connector>
type CustomerDataProps = NBSAdminsTableProps & PropsFromRedux

const NBSAdminTable: React.FC<CustomerDataProps> = (props) => {
  const initialFilter = React.useMemo(() => {
    return {
      level: Object.values(NBS_ADMIN_LEVEL),
      status: FilterDropdownInitialStatus,
      from: '',
      to: moment().utc().format('Y-MM-DD HH:mm'),
      isReset: false,
    }
  }, [])

  const [data, setData] = useState<UserModel[]>([])
  const [show, setShow] = useState<boolean>(false)
  const [receiver, setReceiver] = useState<UserModel>()
  const [, setFilter] = useState(initialFilter)

  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }: any) => {
    const options = React.useMemo(() => {
      const options = new Set<any>()
      preFilteredRows.forEach((row: any) => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (
      <MultiSelect
        options={options}
        value={filterValue || []}
        onChange={(selected) => {
          setFilter(selected)
        }}
      />
    )
  }

  const renderActions = useCallback(
    (value: any) => (
      <div className='d-flex justify-content-end flex-shrink-0'>
        <span
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          title='Send Notification'
          onClick={() => handleSelect(value.row.original)}
        >
          <KTSVG path='/media/icons/duotone/Communication/Group-chat.svg' className='svg-icon-3' />
        </span>
        <Link
          to={`/nbs-admins/invite/${value.row.original.id}`}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          title='Resend-Invite'
        >
          <KTSVG path='/media/icons/duotone/General/Settings-1.svg' className='svg-icon-3' />
        </Link>
        {!levelHelper.isCustomerTechnician(props.auth.user?.level) &&
          <>
            <Link
              to={`/nbs-admins/edit/${value.row.original.id}`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              title='Edit'
            >
              <KTSVG path='/media/icons/duotone/Communication/Write.svg' className='svg-icon-3' />
            </Link>
            <Link
              to={`/nbs-admins/delete/${value.row.original.id}`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              title='Delete'
            >
              <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
            </Link>
          </>
        }
      </div>
    ),
    [props]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'NBS Employees',
        id: 'basic-info',
        accessor: (originalRow: any) => ({
          id: originalRow.id,
          name: `${originalRow.firstname} ${originalRow.lastname}`,
          customerId: originalRow.customerId,
          avatar: originalRow.avatar,
        }),
        Cell: ({ cell: { value } }: any) => reanderBasicInfo(value),
        filter: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ cell: { value } }: any) => renderSimpleString(value),
        filter: 'includes',
      },
      {
        Header: 'Level',
        accessor: 'level',
        Cell: ({ cell: { value } }: any) => renderSimpleString(value),
        filter: 'selectIncludes',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Active on',
        accessor: 'lastLogin',
        Cell: ({ cell: { value } }: any) => renderDate(value),
        disableFilters: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }: any) => renderSimpleString(value),
        filter: 'selectIncludes',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Actions',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ cell }: any) => renderActions(cell),
        className: 'text-end',
      },
    ],
    [renderActions]
  )

  const activeUsersCnt = useMemo(
    () => props.data.filter((c: any) => c.status === CUSTOMER_STATUS.ACTIVE).length,
    [props.data]
  )
  const invitedUsersCnt = useMemo(
    () => props.data.filter((c: any) => c.status === CUSTOMER_STATUS.INVITED).length,
    [props.data]
  )

  const handleHide = () => {
    setShow(false)
  }

  const handleSelect = (user: UserModel) => {
    setShow(true)

    setReceiver(user)
  }

  const hanldeSearch = (value: string) => {
    setData(
      props.data.filter((admin: UserModel) => {
        return (
          `${admin.firstname} ${admin.lastname}`.toUpperCase().includes(value.toUpperCase()) ||
          admin.email?.toUpperCase().includes(value.toUpperCase()) ||
          admin.level?.toUpperCase().includes(value.toUpperCase()) ||
          admin.status?.toUpperCase().includes(value.toUpperCase())
        )
      })
    )
  }

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const reanderBasicInfo = (value: any) => (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-45px me-5'>
        <img
          src={checkIfImageExists(value.avatar)}
          alt={value.name}
          className='mw-45px mh-45px mw-md-45px mh-md-45px w-auto h-auto'
        />
      </div>
      <div className='d-flex justify-content-start flex-column'>
        <Link
          to={`/nbs-admins/view/${value.id}`}
          className='text-dark fw-bolder text-hover-primary fs-6'
        >
          {`${value.name}`}
        </Link>
        <span className='text-muted fw-bold text-muted d-block fs-7'>{value.customerId}</span>
      </div>
    </div>
  )

  const renderDate = (value: any) => {
    let time = getTimeInCentralTime(value)

    return (
      <div className='d-flex align-items-center justify-content-start'>
        <p className='text-dark fw-bolder fs-6 capitalize mb-0'>{time}</p>
      </div>
    )
  }

  const renderSimpleString = (value: any) => (
    <div className='d-flex align-items-center justify-content-start'>
      <p className='text-dark fw-bolder fs-6 capitalize mb-0'>{value}</p>
    </div>
  )

  const handleFilter = useCallback(
    (filters: any) => {
      const { isReset } = filters

      if (isReset === true) {
        setFilter(initialFilter)

        props.getNBSAdmins(initialFilter)
      } else {
        setFilter(filters)

        props.getNBSAdmins(filters)
      }
    },
    [initialFilter, props]
  )

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  return (
    <>
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>NBS Admin Administration</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Invited: {invitedUsersCnt}</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Active: {activeUsersCnt}</span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Link to='/nbs-admins/new' className='btn btn-sm btn-light-primary'>
              <KTSVG path='media/icons/duotone/Communication/Add-user.svg' className='svg-icon-3' />
              New NBS Admin
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <Divider style={{ backgroundColor: 'rgba(0,0,0,.5)', marginBottom: 16 }} />
          <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Admins'
                onChange={(e) => hanldeSearch(e.target.value)}
              />
            </div>
            <div className='card-toolbar'>
              <div className='d-flex' data-kt-customer-table-toolbar='base'>
                <div className='filter-dropdown ms-3'>
                  <button
                    type='button'
                    className='btn btn-light-primary me-3'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Filter options'
                    ref={filterRef}
                  >
                    <KTSVG
                      path='/media/icons/duotone/Text/Filter.svg'
                      className='svg-icon-2 text-light'
                    />
                    Filter
                  </button>
                  <FilterDropDown
                    handleFilter={handleFilter}
                    handleCancelCustomer={handleCancelCustomer}
                    isNBS={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='table-responsive' style={{ minHeight: 200, maxHeight: 600 }}>
            {data.length > 0 && <Table columns={columns} data={data} />}
            {!data.length && (
              <Typography>There are no customers yet. Please invite one.</Typography>
            )}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <SendNotification show={show} onHide={handleHide} receiver={receiver} type='admin' />
    </>
  )
}

export default connector(NBSAdminTable)
