import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer } from 'react-leaflet';
import { PageTitle } from '../../../_metronic/layout/core'
import { getCustomerData } from './TerritoryMapCrud';
import axios from 'axios';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';

// This is the google geocoding api key that is being used
//  AIzaSyB2NntPupn-UqDUeQN3Q-W9sEKT_cmLaJ4

// I use leaflet to create a map, with the initial use being having pins for each customer

function createRedPin(desiredWidth: number): L.Icon {
  // As the name implies it creates a pin object for the map
  // Original pin dimensions
  const originalWidth = 66;
  const originalHeight = 143;

  // Calculate scale factor and new height
  const scaleFactor = desiredWidth / originalWidth;
  const desiredHeight = Math.round(originalHeight * scaleFactor);

  // Create and return new Leaflet icon
  const pin = L.icon({
      iconUrl: '/media/logos/pin.png',
      iconSize: [desiredWidth, desiredHeight],
      iconAnchor: [desiredWidth / 2, desiredHeight],
      popupAnchor: [-3, -76]
  });

  return pin;
}


const TerritoryMapPage: React.FC = () => {
  const [map, setMap] = useState<L.Map | null>(null);

  useEffect(() => {
    if (map === null) {
      return;
    }
    // Add a tile layer to the map
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);

    // pin icon location
    //portal_frontendv2/src/app/pages/territory-map/pin.png
    // Create a standard red pin
    var pin = createRedPin(15)
   
    const fetchCustomerData = async () => {
      // Gets all customer data and inserts them as pins
      try {
        const response = await getCustomerData();
        for (var i = 0; i < Object.keys(response.data).length; i++) {
          if (response.data[i] !== undefined) {
            // Create a customer pin
            var customerInfo = response.data[i].CompanyName + " " + response.data[i].CustomerID + "<br>Ship to: " + response.data[i].ShipAddres + ", " + response.data[i].ShipCity + ", " + response.data[i].ShipState + ", " + response.data[i].ShipZip;
            L.marker([response.data[i].lat, response.data[i].long], {icon: pin}).addTo(map).bindPopup(customerInfo);
          }
        }
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
    };

    fetchCustomerData();
    
  }, [map]);

  return (
    <>
    <div>
      <MapContainer
        id="map"
        center={[37.8, -96]}
        zoom={4}
        style={{ height: '1000px' }}
        whenCreated={setMap}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </MapContainer>
    </div>
    </>
  );
};

export default TerritoryMapPage;
