import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import {
  AccreditReports,
  IArrayEquipmentTest,
} from '../../../../types/accredit-reports/AccreditReportsTypes'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { CheckIsAdjusted } from '../helper/PdfStylingCheck'
const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell1: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '30%',
    padding: 2,
  },
  cell2: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '15%',
    padding: 2,
  },
  cell3: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '15%',
    padding: 2,
  },
  cell4: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '30%',
    padding: 2,
  },
  cell5: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '10%',
    padding: 2,
  },
  big_cell: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  body: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
})
type Props = { data: IArrayEquipmentTest }

const PerformanceCheckTable1 = (props: Props) => {
  const { data } = props
  // Calculate tolerance_q2_q3 inside the component
  const tolerance_q2_q3 = `${data.TOL4}`

  const formatTolerance = (tol: string) => {
    if (tol == '0') {
      return '0-0'
    }
    if (tol.includes('-')) {
      return `${tol}`
    }
  }
  function getSecondDecimal(toleranceString: string) {
    // This gets the second tolerance value
    const matches = toleranceString.match(/(\d+\.\d+) - (\d+\.\d+)/);
    return matches ? parseFloat(matches[2]) : null;
  }
  
  function getDecimalPart(num: number) {
    // This gets the decimal part of a number
    return num % 1;
  }
  function convertToDecimal(num: string) {
    return Number(num) / 100;
  }
  const checkTolerance = (
    division: string,
    cornerLoadTolerance: string,
    weightOne: string,
    weightTwo: string
  ) => {
    if (
      Number(weightOne) * Number(division) > Number(cornerLoadTolerance) ||
      Number(weightTwo) * Number(division) > Number(cornerLoadTolerance)
    ) {
      return false
    } else {
      return true
    }
    const tolerance_q2_q3 = `${Number(data.CornerLoadValue) - Number(data.CornerLoadTolerance)} - ${
      Number(data.CornerLoadValue) + Number(data.CornerLoadTolerance)
    }`
  }
    // Extract the second decimal from tolerance_q2_q3
    const secondDecimal = getSecondDecimal(tolerance_q2_q3);
    var secondDecimalPart = 0; // Default value
    if (secondDecimal !== null) {
      var secondDecimalPart = getDecimalPart(secondDecimal);
   }
    let inTolerance = true;

    // Assuming data is an object with the required properties
    if (data && secondDecimal !== null) {
      // Check if any of the values' decimal parts are greater than the second decimal part
      if (getDecimalPart(convertToDecimal(data.CornerLoadFrontAsLeft)) > secondDecimalPart ||
      getDecimalPart(convertToDecimal(data.CornerLoadBackAsLeft)) > secondDecimalPart ||
      getDecimalPart(convertToDecimal(data.CornerLoadLeftAsLeft)) > secondDecimalPart ||
      getDecimalPart(convertToDecimal(data.CornerLoadRightAsLeft)) > secondDecimalPart) {
        inTolerance = false;
     }
    }
  return (
    <View style={styles.root}>
      <View>
        <View style={styles.header}>
          <View style={styles.cell1}>
            <Text style={{ textAlign: 'center' }}>Mass Value</Text>
          </View>
          <View style={styles.cell2}>
            <Text>As-Found</Text>
          </View>
          <View style={styles.cell3}>
            <Text>In-Tolerancee</Text>
          </View>
          <View style={styles.cell4}>
            <Text>Tolerance</Text>
          </View>
          <View style={styles.cell5}>
            <Text>As-Left</Text>
          </View>
        </View>

        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>Quadrant 2 / Quadrant 3</Text>
          </View>
          <View style={styles.cell2}>
            <Text>{`${data?.CornerLoadFront || '0'}/${data.CornerLoadBack || '0'}`}</Text>
          </View>
          <View style={styles.cell3}>
            <Text
              style={{
                color: checkTolerance(
                  data.Division,
                  data.CornerLoadTolerance,
                  data.CornerLoadFront,
                  data.CornerLoadBack
                )
                  ? 'green'
                  : 'red',
              }}
            >
              {checkTolerance(
                data.Division,
                data.CornerLoadTolerance,
                data.CornerLoadFront,
                data.CornerLoadBack
              )
                ? 'Yes'
                : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text> {formatTolerance(tolerance_q2_q3) ?? '0'}</Text>
          </View>
          <View style={styles.cell5}>
            <Text>{`${data?.CornerLoadFrontAsLeft || 'null'}/${
              data.CornerLoadBackAsLeft || 'null'
            }`}</Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>Quadrant 1 / Quadrant 4</Text>
          </View>
          <View style={styles.cell2}>
            <Text>{`${data?.CornerLoadLeft || '0'}/${data.CornerLoadRight || '0'}`}</Text>
          </View>
          <View style={styles.cell3}>
            <Text
              style={{
                color: checkTolerance(
                  data.Division,
                  data.CornerLoadTolerance,
                  data.CornerLoadLeft,
                  data.CornerLoadRight
                )
                  ? 'green'
                  : 'red',
              }}
            >
              {checkTolerance(
                data.Division,
                data.CornerLoadTolerance,
                data.CornerLoadLeft,
                data.CornerLoadRight
              )
                ? 'Yes'
                : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text> {formatTolerance(tolerance_q2_q3) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>{`${data?.CornerLoadLeftAsLeft || 'null'}/${
              data.CornerLoadRightAsLeft || 'null'
            }`}</Text>
          </View>
        </View>
      </View>
      <View style={[styles.cell4, styles.big_cell]}>
        <Text>
            In Tolerance <Image src={toAbsoluteUrl('/media/arrow.png')} />:{' '}
            <span style={{ color: inTolerance ? 'green' : 'red' }}>{inTolerance ? 'Yes' : 'No'}</span>
        </Text>
        <Text>
          Adjusted Calibration:{' '}
          <span>
            {CheckIsAdjusted(
              [
                data.CornerLoadFront,
                data.CornerLoadBack,
                data.CornerLoadLeft,
                data.CornerLoadRight,
              ],
              [
                data.CornerLoadFrontAsLeft,
                data.CornerLoadBackAsLeft,
                data.CornerLoadLeftAsLeft,
                data.CornerLoadRightAsLeft,
              ]
            )}
          </span>
        </Text>
      </View>
    </View>
  )
}

export default PerformanceCheckTable1
