import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import toast from 'react-hot-toast'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Accordion } from 'react-bootstrap-v5'
import { statusType, Customer, UserModel } from '../../../../types'
import { getOrderFilterStatus } from '../../../pages/sales-orders/salesOrdersCrud'
import { getAllCustomerIds } from '../../../pages/asset-mangement/AssetManagementCrud'
import { SearchDropDown } from '../../../components/SearchDropDown'
import { RootState } from '../../../../setup'
import { getErrorMessage } from '../../../../helper/response.helper'
import CustomToggle from '../../../components/CustomToggle'
import FilterList from '../../../components/FilterList'
import * as levelHelper from '../../../../helper/level.helper'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { KTSVG } from '../../../../_metronic/helpers'

type FilterDropDownProps = {
  handleFilter: (filter: any) => void
  handleCancelCustomer: () => void
  initialFilterStatus?: any
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({
  handleFilter,
  handleCancelCustomer,
  initialFilterStatus = ['active'],
}) => {
  const initialEndDate = new Date()
  const initialStartDate = new Date()
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1)
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [startDate, setStartDate] = useState<any>(initialStartDate)
  const [endDate, setEndDate] = useState<any>(initialEndDate)
  const [status, setStatus] = useState<Array<string>>(initialFilterStatus)
  const [filterStatus, setFilterStatus] = useState<statusType[]>([])
  const [selectAllStatus, setSelectAllStatus] = useState<boolean>(false)
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; name: string | undefined }[]
  >([])

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const handleChange = (state: string, isChecked: boolean) => {
    const stateDbKey = filterStatus.find((s) => s.description === state)?.dbkey

    if (isChecked === true) setStatus([...status, stateDbKey ? stateDbKey : ''])
    else setStatus(status.filter((s) => s !== stateDbKey))
  }

  const handleSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
    setIsReset(false)
  }

  const handleCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }

  const handleReject = () => {
    setStatus(initialFilterStatus)
    setStartDate(initialStartDate)
    setEndDate(initialEndDate)
    setIsReset(true)
    handleFilter({
      isReset: true,
    })
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))
    getOrderFilterStatus()
      .then((res) => {
        setFilterStatus(res.data as statusType[])
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })

    if (levelHelper.isNBS(user?.type)) {
      setSelectedCustomers([])
      getAllCustomerIds().then((res) => {
        setCustomers(res.data)
      })
    } else {
      let uCustomers: Array<Customer> = []

      if (user.customerIds) {
        user.customerIds.forEach((c) => {
          uCustomers.push({ id: c.customerId, name: c.companyName })
        })
      }
      setCustomers(uCustomers)
    }
  }, [user])

  // Handler when change select all for status
  const handleSelectAllStatus = () => {
    if (!selectAllStatus) {
      setStatus(filterStatus.map((s) => s.dbkey))
    } else {
      setStatus([])
    }
  }

  useEffect(() => {
    if (filterStatus.every((s1) => status.some((s2) => s2 === s1.dbkey))) {
      setSelectAllStatus(true)
    } else {
      setSelectAllStatus(false)
    }
  }, [status, filterStatus])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          <>
            <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
              <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
            </div>
            <Accordion.Collapse eventKey='filter-0' className='ms-8'>
              <>
                <div>
                  {selectedCustomers?.map((customer, index) => {
                    return (
                      <div
                        key={index}
                        className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                      >
                        <span>
                          {customer.id} - {customer?.name}
                        </span>
                        <span
                          className='text-hover-primary cursor-pointer'
                          onClick={() => handleCancel(customer.id)}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Navigation/Close.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                    )
                  })}
                </div>
                <SearchDropDown
                  data={customers}
                  edit={isReset ? 'reset' : ''}
                  isNBS={isNBS}
                  selectedValue={(customer) => handleSelect(customer)}
                />
              </>
            </Accordion.Collapse>
          </>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-1'>Status</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-1' className='ms-8'>
            <ul className='list-unstyled'>
              <FilterList
                key={`status-all`}
                status={'Select All'}
                isChecked={selectAllStatus}
                handleChange={handleSelectAllStatus}
              />
              <li className='separator border-gray-200 mb-1'></li>
              {filterStatus.map((state, index) => {
                return (
                  <FilterList
                    key={`status-${index}`}
                    status={state.description}
                    isChecked={!!status.includes(state.dbkey)}
                    handleChange={handleChange}
                  />
                )
              })}
            </ul>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-2'>Created on</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-2'>
            <>
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={startDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={endDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  selectedCustomers: selectedCustomers,
                  status: status,
                  from: moment(startDate).utc().format('Y-MM-DD HH:mm'),
                  to:
                    endDate || endDate !== ''
                      ? moment(endDate).utc().format('Y-MM-DD HH:mm')
                      : moment().utc().format('Y-MM-DD HH:mm'),
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
