import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { UserModel } from '../../../types'
import { RootState } from '../../../setup'
import { Link, Redirect, useParams } from 'react-router-dom'
import { getUniqueCompanyNames } from '../../../helper/user.helper'
import * as levelHelper from '../../../helper/level.helper'
import * as customersRedux from './redux/customerRedux'

interface ReduxStateValueProps {
  user: UserModel | undefined
  customers: UserModel[]
}
type ID = {
  id: string
}

const mapState = (state: RootState) => ({
  user: state.auth?.user,
  customers: state.customers.customers,
  customerIds: state.auth.activeCustomerIds,
  auth: state.auth,
})
const connector = connect(mapState, {
  ...customersRedux.actions,
})
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = ReduxStateValueProps & PropsFromRedux

const ViewCustomer: React.FC<Props> = (props) => {
  const { user, customers, getCustomers, customerIds } = props
  const { id } = useParams<ID>()
  const customer = customers.find((customer: UserModel) => customer.id === parseInt(id))
  const isAdmin = levelHelper.isCustomerAdmin(user?.level)

  useEffect(() => {
    getCustomers(customerIds)
  }, [customerIds, getCustomers])

  if (!id) return <Redirect to='/dashboard' />

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Profile Details</h3>
          </div>

          {isAdmin && (
            <Link
              to={`/customers/edit/${customer?.id}`}
              className='btn btn-primary align-self-center'
            >
              Edit Profile
            </Link>
          )}
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{`${customer?.firstname} ${customer?.lastname}`}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Email</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 me-2'> {customer?.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Customer ID</label>

            <div className='col-lg-8 fv-row'>
              {customer?.customerIds &&
                customer?.customerIds.length &&
                customer?.customerIds.map((id: any) => (
                  <span className='fw-bold fs-6 d-block'>{`${id.customerId} - ${id.companyName}`}</span>
                ))}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company Name</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{getUniqueCompanyNames(customer?.customerIds)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connector(ViewCustomer)
