import { FC } from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { remToPx } from '../../../../helper'

type Props = {
  data: any
  canEdit: boolean
  canDelete?: boolean
  handleEdit: (data: any) => void
  handleView: (data: any) => void
  handleDelete?: (data: any) => void
}

const ActionDropDown: FC<Props> = (props: any) => {
  const { data, handleEdit, handleView, canEdit, canDelete, handleDelete } = props

  return (
    <Dropdown>
      <Dropdown.Toggle id='dropdown-basic' variant='light' size='sm'>
        <KTSVG path='/media/icons/duotone/General/gen052.svg' className='svg-icon-5 m-0' />
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: () => [0, remToPx(0.5)], // mt-2
              },
            },
          ],
        }}
      >
        {canEdit && (
          <Dropdown.Item href='#' onClick={() => handleEdit(data)}>
            Edit
          </Dropdown.Item>
        )}
        <Dropdown.Item href='#' onClick={() => handleView(data)}>
          View
        </Dropdown.Item>
        {canDelete && (
          <Dropdown.Item href='#' onClick={() => handleDelete(data)}>
            Delete
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ActionDropDown }
