import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useMemo } from 'react'
import {
  AccreditReports,
  IArrayEquipmentTest,
} from '../../../../types/accredit-reports/AccreditReportsTypes'
import { CheckIsAdjusted, PerformanceAsLeftTolerance } from '../helper/PdfStylingCheck'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell1: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '15%',
    padding: 2,
  },
  cell2: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',
    width: '15%',
    padding: 2,
  },
  cell3: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '15%',
    padding: 2,
  },
  cell4: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '40%',
    padding: 2,
  },
  cell5: {
    border: '1px solid black',
    textAlign: 'center',
    alignItems: 'center',

    width: '15%',
    padding: 2,
  },
  big_cell: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  body: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
})
type Props = { data: IArrayEquipmentTest }

const PerformanceCheckTable = (props: Props) => {
  const { data } = props
  // Use nullish coalescing to provide a default empty string if data.Division is null or undefined.
    // Ensure data.Division is a string before calling replace. If it's null or undefined, use an empty string.
    data.Division = typeof data?.Division === 'string' ? data.Division.replace(/0+$/, '') : '';

    // Apply the same logic for data.DivisionDual
    data.DivisionDual = typeof data?.DivisionDual === 'string' ? data.DivisionDual.replace(/0+$/, '') : '';
  let decimalPlaces = data?.Division.includes('.') ? data?.Division.split('.')[1].length : 0
  let decimalPlacesDual = data?.DivisionDual.includes('.')
    ? data?.DivisionDual.split('.')[1].length
    : 0
  const handleInTolerance = (WtF: string, TOL: string) => {
    if (TOL == '0') {
      return true
    }

    let tol1 = Number(TOL.split('-')[0])
    let tol2 = Number(TOL.split('-')[1])
    console.log(tol1, WtF, tol2)
    if (tol1 <= Number(WtF) && tol2 >= Number(WtF)) {
      return true
    } else {
      return false
    }
  }
  const formatTolerance = (tol: string) => {
    if (tol == '0') {
      return '0-0'
    }
    if (tol.includes('-')) {
      let tol1 = tol.split('-')[0]
      let tol2 = tol.split('-')[1]
      return `${tol1}-${tol2}`
    }
  }
  const determineDecimalPlaces = (
    number: number,
    capacity: string,
    divisionLen: number,
    dualLen: number
  ): string => {
    let result

    if (capacity.includes('/')) {
      const capacityValues = capacity.split('/').map(Number)
      if (number < capacityValues[0]) {
        result = number.toFixed(divisionLen)
      } else {
        result = number.toFixed(dualLen)
      }
    } else {
      result = number.toFixed(divisionLen)
    }

    return isNaN(parseFloat(result)) ? 'OVERLOAD' : result
  }

  return (
    <View style={styles.root}>
      <View>
        <View style={styles.header}>
          <View style={styles.cell1}>
            <Text style={{ textAlign: 'center' }}>Mass Value</Text>
          </View>
          <View style={styles.cell2}>
            <Text>As-Found</Text>
          </View>
          <View style={styles.cell3}>
            <Text>In-Tolerance</Text>
          </View>
          <View style={styles.cell4}>
            <Text>Tolerance</Text>
          </View>
          <View style={styles.cell5}>
            <Text>As-Left</Text>
          </View>
        </View>
        {console.log()}
        {console.log('YN', data.ynA2LACalRep)}
        {data.ynA2LACalRep != 0 && (
          <>
            <View style={styles.body}>
              <View style={styles.cell1}>
                <Text>{Number(data?.WU1) || '0'} </Text>
              </View>
              <View style={styles.cell2}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtF1),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
              <View style={styles.cell3}>
                <Text style={{ color: handleInTolerance(data.WtF1, data.TOL1) ? 'green' : 'red' }}>
                  {handleInTolerance(data.WtF1, data.TOL1) ? 'Yes' : 'No'}
                </Text>
              </View>
              <View style={styles.cell4}>
                <Text>{formatTolerance(data.TOL1) ?? '0'}</Text>
              </View>
              <View style={styles.cell5}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtL1),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
            </View>
            <View style={styles.body}>
              <View style={styles.cell1}>
                <Text>{Number(data?.WU2) || '0'} </Text>
              </View>
              <View style={styles.cell2}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtF2),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
              <View style={styles.cell3}>
                <Text style={{ color: handleInTolerance(data.WtF2, data.TOL2) ? 'green' : 'red' }}>
                  {handleInTolerance(data.WtF2, data.TOL2) ? 'Yes' : 'No'}
                </Text>
              </View>
              <View style={styles.cell4}>
                <Text>
                  <Text>{formatTolerance(data.TOL2) ?? '0'}</Text>
                </Text>
              </View>
              <View style={styles.cell5}>
                <Text>
                  {determineDecimalPlaces(
                    Number(data?.WtL2),
                    data?.Capacity,
                    decimalPlaces,
                    decimalPlacesDual
                  )}{' '}
                </Text>
              </View>
            </View>
          </>
        )}
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU3) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF3),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF3, data.TOL3) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF3, data.TOL3) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL3) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL3),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU4) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF4),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF4, data.TOL4) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF4, data.TOL4) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL4) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL4),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
        </View>

        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU5) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF5),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF5, data.TOL5) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF5, data.TOL5) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL5) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL5),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}
            </Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.cell1}>
            <Text>{Number(data?.WU6) || '0'} </Text>
          </View>
          <View style={styles.cell2}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtF6),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
          <View style={styles.cell3}>
            <Text style={{ color: handleInTolerance(data.WtF6, data.TOL6) ? 'green' : 'red' }}>
              {handleInTolerance(data.WtF6, data.TOL6) ? 'Yes' : 'No'}
            </Text>
          </View>
          <View style={styles.cell4}>
            <Text>
              <Text>{formatTolerance(data.TOL6) ?? '0'}</Text>
            </Text>
          </View>
          <View style={styles.cell5}>
            <Text>
              {determineDecimalPlaces(
                Number(data?.WtL6),
                data?.Capacity,
                decimalPlaces,
                decimalPlacesDual
              )}{' '}
            </Text>
          </View>
        </View>
      </View>
      <View style={[styles.cell4, styles.big_cell]}>
        {PerformanceAsLeftTolerance(
          data.WtL1,
          data.WtL2,
          data.WtL3,
          data.WtL4,
          data.WtL5,
          data.WtL6,
          data.TOL1,
          data.TOL2,
          data.TOL3,
          data.TOL4,
          data.TOL5,
          data.TOL6,
          data.ynA2LACalRep
        ) == 'Yes' ? (
          <Text>
            In Tolerance <Image src={toAbsoluteUrl('/media/arrow.png')} />:{' '}
            <span style={{ color: 'green' }}>Yes</span>
          </Text>
        ) : (
          <Text>
            In Tolerance: <span style={{ color: 'red' }}>No</span>
          </Text>
        )}

        <Text>
          Adjusted Calibration:{' '}
          <span>
            {CheckIsAdjusted(
              [data.WtF1, data.WtF2, data.WtF3, data.WtF4, data.WtF5, data.WtF6],
              [data.WtL1, data.WtL2, data.WtL3, data.WtL4, data.WtL5, data.WtL6]
            )}
          </span>
        </Text>
      </View>
    </View>
  )
}

export default PerformanceCheckTable
