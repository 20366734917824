import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import AssetManagementPage from '../pages/asset-mangement/AssetManagementPage'
import SalesQuotesPage from '../pages/sales-quotes/SalesQuotesPage'
import SalesOrdersPage from '../pages/sales-orders/SalesOrdersPage'
import ServiceOrdersPage from '../pages/service-orders/ServiceOrdersPage'
import ServiceQuotesPage from '../pages/service-quotes/ServiceQuotesPage'
import CalibrationReportsPage from '../pages/calibration-reports/CalibrationReportsPage'
import DeleteCustomer from '../pages/customer/DeleteCustomer'
import ResendCustomerInvite from '../pages/customer/ResendInvite'
import ContactUsPage from '../pages/contact-us/ContactUsPage'
import NewCustomer from '../pages/customer/NewCustomer'
import ViewCustomer from '../pages/customer/ViewCustomer'
import EditCustomer from '../pages/customer/EditCustomer'
import ViewNBSAdmin from '../pages/nbs-admin/ViewNBSAdmin'
import EditNBSAdmin from '../pages/nbs-admin/EditNBSAdmin'
import DeleteNBSAdmin from '../pages/nbs-admin/DeleteNBSAdmin'
import NewNBSAdmin from '../pages/nbs-admin/NewNBSAdmin'
import ReceiveServiceQuoteApprovals from '../pages/admin-configuration-setting/ReceiveServiceQuoteApprovals'
import ReceiveSalesQuoteApprovals from '../pages/admin-configuration-setting/ReceiveSalesQuoteApprovals'
import ReceiveAssetUpdates from '../pages/admin-configuration-setting/ReceiveAssetUpdates'
import ReceiveServiceRequsts from '../pages/admin-configuration-setting/ReceiveServiceRequests'
import ReceiveGetServiceRequests from '../pages/admin-configuration-setting/ReceiveGetServiceRequests'
import ReceiveContactUs from '../pages/admin-configuration-setting/ReceiveContactUs'
import ReceiveRegistrationAlerts from '../pages/admin-configuration-setting/ReceiveRegistrationAlerts'
import ResendInviteNBSAdmin from '../pages/nbs-admin/ResendInvite'
import CustomerFriendlyStatusCodesPage from '../pages/admin-configuration-setting/customer-friendly-status-codes/CustomerFriendlyStatusCodesPage'
import AccreditReportsPage from '../pages/accredit-reports/AccreditReportsPage'
// import OtherCalReportsPage from '../pages/other-cal-reports/OtherCalReportsPage'
import TerritoryMapPage from '../pages/territory-map/TerritoryMapPage'
import BillingCompanyPage from '../pages/billing-companies/BillingCompanyPage'
import CustomersPage from '../pages/customers/CustomersPage'
import InvoicePage from '../pages/invoice'
import EquipmentReportsPage from '../pages/equipment-reports/EquipmentReportsPage'

export function PrivateRoutes() {
  const LearningCenterWrapper = lazy(() => import('../pages/learning-center/LearningCenterWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const BillingCompanyPage = lazy(() => import('../pages/billing-companies/BillingCompanyPage'))
  // const CustomersPage = lazy(() => import('../pages/customers/CustomersPage'))
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/learning-center/:type' component={LearningCenterWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />

        <Route path='/asset-management' component={AssetManagementPage} />
        <Route path='/territory-map' component={TerritoryMapPage} />
        <Route path='/nbs-customers' component={CustomersPage} />

        <Route path='/sales-quotes' component={SalesQuotesPage} />
        <Route path='/sales-orders' component={SalesOrdersPage} />
        <Route path='/service-orders' component={ServiceOrdersPage} />
        <Route path='/service-quotes' component={ServiceQuotesPage} />
        <Route path='/calibration-reports' component={CalibrationReportsPage} />
        <Route path='/accredit-reports' component={AccreditReportsPage} />
        <Route path='/equipment-reports' component={EquipmentReportsPage} />

        {/* <Route path='/other-cal-reports' component={OtherCalReportsPage} /> */}

        <Route path='/contact-us' component={ContactUsPage} />

        {/* Billing Company */}
        <Route path='/nbs-billing-companies' component={BillingCompanyPage} />

        <Route path='/customers/new' component={NewCustomer} />
        <Route path='/customers/delete/:id' component={DeleteCustomer} />
        <Route path='/customers/view/:id' component={ViewCustomer} />
        <Route path='/customers/edit/:id' component={EditCustomer} />
        <Route path='/customers/invite/:id' component={ResendCustomerInvite} />

        <Route path='/nbs-admins/new' component={NewNBSAdmin} />
        <Route path='/nbs-admins/delete/:id' component={DeleteNBSAdmin} />
        <Route path='/nbs-admins/view/:id' component={ViewNBSAdmin} />
        <Route path='/nbs-admins/edit/:id' component={EditNBSAdmin} />
        <Route path='/nbs-admins/invite/:id' component={ResendInviteNBSAdmin} />

        <Route
          path='/admin-configuration-setting/receive-service'
          component={ReceiveServiceQuoteApprovals}
        />
        <Route
          path='/admin-configuration-setting/receive-sales'
          component={ReceiveSalesQuoteApprovals}
        />
        <Route path='/admin-configuration-setting/receive-asset' component={ReceiveAssetUpdates} />
        <Route
          path='/admin-configuration-setting/receive-new-service'
          component={ReceiveServiceRequsts}
        />
        <Route
          path='/admin-configuration-setting/receive-get-service'
          component={ReceiveGetServiceRequests}
        />
        <Route path='/admin-configuration-setting/receive-contactus' component={ReceiveContactUs} />

        <Route
          path='/admin-configuration-setting/receive-registration-alerts'
          component={ReceiveRegistrationAlerts}
        />

        <Route path='/customer-friendly-status-codes' component={CustomerFriendlyStatusCodesPage} />

        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/invoice' component={InvoicePage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
