/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import toast from 'react-hot-toast'
import moment from 'moment'
import { Card } from 'react-bootstrap-v5'
import { TableColumn } from 'react-data-table-component'
import { RootState } from '../../../setup'
import { QuoteModel, PaymentOption, fileType, Customer } from '../../../types'
import { PageTitle } from '../../../_metronic/layout/core'
import { rejectQuote, commentQuote, getQuoteAttachment, approveQuote } from './salesQuotesCrud'
import { AttachmentModal } from '../service-quotes/components/AttachmentModal'
import { getErrorMessage, customQuotesSort } from '../../../helper'
import { KTSVG } from '../../../_metronic/helpers'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { Description } from '../service-quotes/components/Description'
import { ActionDropDown } from '../service-quotes/components/ActionDropDown'
import { updateQuotePaymentOption } from '../../services/payment'
import { ConfirmModal } from '../../components/_modal/ConfirmModal'
import UpdatePaymentOption from '../../components/_modal/UpdatePaymentOption'
import DataTableBase from '../../components/DataTableBase'
import BaseModal from '../../components/_modal/BaseModal'
import FilterDropDown from '../../modules/service/_dropdown/FilterDropDown'
import ApproveModal from '../../components/_modal/ApproveDialog'

import * as levelHelper from '../../../helper/level.helper'
import * as salesQuotesRedux from './salesQuotesRedux'

interface ReduxStateValueProps {
  salesQuotes: QuoteModel[]
  loading: boolean
  totals: Number
  quoteNumberSlug: any
  customerIds: any
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  salesQuotes: state.salesQuotes.salesQuotes,
  loading: state.salesQuotes.loading,
  quoteNumberSlug: state.salesQuotes.quoteNumberSlug,
  totals: state.salesQuotes.totals,
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, { ...salesQuotesRedux.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type SalesQuotesProps = ReduxStateValueProps & PropsFromRedux

const SalesQuotesPage: React.FC<SalesQuotesProps> = ({ auth, ...props }) => {
  const initialActionModalsShow = {
    approve: false,
    reject: false,
  }

  const initialFilter = {
    status: ['Active'],
    confirm: ['Yes', 'No'],
    from: moment().subtract(1, 'years').utc().format('Y-MM-DD HH:mm'),
    to: moment().utc().format('Y-MM-DD HH:mm'),
    isReset: false,
  }

  const [isNBS, setIsNBS] = useState<boolean>(levelHelper.isNBS(auth.user?.type))
  const [slug, setSlug] = useState<string>('')
  const [quoteNumberSlug, setQuoteNumberSlug] = useState<any>(props.quoteNumberSlug)
  const [quotes, setQuotes] = useState<QuoteModel[]>([])
  const [currentQuote, setCurrentQuote] = useState<QuoteModel>()
  const [show, setShow] = useState<boolean>(false)
  const [showComment, setShowComment] = useState<boolean>(false)
  const [commentCustomerId, setCommentCustomerId] = useState<string>('')
  const [commentEmail, setCommentEmail] = useState<string>('')
  const [attachQuoteId, setAttachQuoteId] = useState<number>(0)
  const [approveQuoteId, setApproveQuoteId] = useState<number>(0)
  const [updateQuoteId, setUpdateQuoteId] = useState<number>(0)
  const [approveCustomerId, setApproveCustomerId] = useState<string>('')
  const [updateCustomerId, setUpdateCustomerId] = useState<string>('')
  const [quoteNumber, setQuoteNumber] = useState()
  const [attachment, setAttachment] = useState<fileType[]>([])
  const [nbsCustomerId, setNbsCustomerId] = useState<string[]>(['nbs'])
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
  const [actionModalsShow, setActionModalsShow] = useState(initialActionModalsShow)
  const [quoteAction, setQuoteAction] = useState<PaymentOption[]>([])
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false)
  const [updateStatus, setUpdateStatus] = useState<boolean>(false)
  const [inProgress, setInProgress] = useState(false)
  const [filter, setFilter] = useState(initialFilter)
  const [approveStatus, setApproveStatus] = useState<boolean>(false)
  const [confirmAlert, setConfirmAlert] = useState({
    show: false,
    content: '',
    onConfirm: () => { },
  })

  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDir, setSortDir] = useState(null)

  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const hideActionModals = useCallback(() => {
    setActionModalsShow(initialActionModalsShow)
  }, [actionModalsShow])

  const showConfirmAlert = useCallback(
    (content: string, onConfirm: any) => {
      setConfirmAlert({ show: true, content, onConfirm })
    },
    [confirmAlert]
  )

  const handleAttachmentModal = useCallback((dbKey: number) => {
    setShowAttachmentModal(true)
    setAttachQuoteId(dbKey)

    getQuoteAttachment(dbKey)
      .then((res) => {
        let attachments: fileType[] = []

        res.data.forEach((name: string) => {
          attachments.push({ name: name, size: 0, type: 'old', checked: false })
        })
        setAttachment(attachments)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }, [])

  const getQuotes = () => {
    props.getSalesQuotes({
      customerIds: isNBS ? nbsCustomerId : props.customerIds,
      status: filter.status,
      confirm: filter.confirm,
      from: filter.from,
      to: filter.to,
      page,
      perPage,
      slug,
      quoteNumberSlug,
      quotePrefix: auth.user?.quotePrefix,
      sortColumn,
      sortDir,
    })
  }

  const modalProps = {
    onHide: hideActionModals,
    quoteNumber,
    quoteId: approveQuoteId,
    customerId: approveCustomerId,
    showConfirmAlert: showConfirmAlert,
  }

  const columns: TableColumn<QuoteModel>[] = useMemo(
    () => [
      {
        name: '',
        cell: (row) => (
          <span
            className='btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable'
            onClick={() => handleAttachmentModal(row.dbkey)}
          >
            <KTSVG path='/media/icons/duotone/Communication/paperslip.svg' className='svg-icon-2' />
            {row.attachment && row.attachment.length !== 0 && (
              <span className='attachment-count'>{row.attachment && row.attachment.length}</span>
            )}
          </span>
        ),
        width: '30px',
        style: {
          minWidth: '30px !important',
          maxWidth: '30px !important',
        },
      },
      {
        name: 'Actions',
        cell: (row) => (
          <ActionDropDown
            data={row}
            auth={auth}
            handleReject={() => {
              setShow(true)
              setCurrentQuote(row)
            }}
            handleComment={() => {
              setShowComment(true)
              setCurrentQuote(row)
              setCommentCustomerId(row.customerId)
              setCommentEmail(row.email)
            }}
            handleApprove={() => handleApprove(row)}
            handleUpdatePayment={(row) => handleUpdatePayment(row)}
          />
        ),
        width: '100px',
        style: {
          minWidth: '100px !important',
          maxWidth: '100px !important',
        },
      },
      {
        name: 'CUSTOMER',
        sortField: 'id',
        selector: (row) => row.customerId,
        cell: (row) => (
          <span>
            <span>{row.customerId}</span>
            <br />
            <span>{row.customerName}</span>
          </span>
        ),
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        name: 'QUOTE NUMBER',
        sortField: 'quote_number',
        selector: (row) => row.quoteNumber,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        name: <span style={{ whiteSpace: 'break-spaces' }}>TRANSACTION TYPE</span>,
        sortField: 'transactionType',
        selector: (row) => row.transactionType,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
          whiteSpace: 'break-spaces',
        },
      },
      {
        name: 'ATTENTION',
        selector: (row) => row.attention,
        sortField: 'attention',
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        name: 'CREATED ON',
        selector: ({ createdAt }) => createdAt?.valueOf(),
        sortField: 'created',
        cell: ({ createdAt }) => moment(createdAt).format('MM/DD/YY'),
        sortable: true,
        width: '8%',
        style: {
          minWidth: '8% !important',
          maxWidth: '8% !important',
        },
      },
      {
        name: 'NBS REP',
        sortField: 'sales_rep',
        selector: (row) => row.salesRep ?? '',
        cell: (row) => <div style={{ whiteSpace: 'break-spaces' }}>{row.salesRep}</div>,
        sortable: true,
        width: '8%',
        style: {
          minWidth: '8% !important',
          maxWidth: '8% !important',
        },
      },
      {
        name: 'Description',
        cell: (row) => <Description data={row} />,
        sortable: true,
        width: 'calc(43% - 300px)',
        style: {
          minWidth: 'calc(43% - 300px) !important',
          maxWidth: 'calc(43% - 300px) !important',
        },
      },
      {
        name: 'Status',
        selector: (row) => row.status,
        sortField: 'status',
        sortable: true,
        width: '70px',
        style: {
          minWidth: '70px !important',
          maxWidth: '70px !important',
        },
      },
      {
        name: 'Confirmed',
        selector: (row) => row.confirmed,
        sortField: 'confirmed',
        sortable: true,
        width: '100px',
        style: {
          minWidth: '100px !important',
          maxWidth: '100px !important',
        },
      },
    ],
    []
  )

  const handleReject = (data: any) => {
    setInProgress(true)
    rejectQuote({
      quoteId: currentQuote?.dbkey === undefined ? 0 : currentQuote?.dbkey,
      comments: data.comment,
      status: 'reject',
    })
      .then((res) => {
        toast.success(res.data)

        getQuotes()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setInProgress(false)
        setShow(false)
      })
  }

  const handleComment = (data: any) => {
    setInProgress(true)
    commentQuote({
      quoteId: currentQuote?.dbkey === undefined ? 0 : currentQuote?.dbkey,
      comments: data.comment,
      isNBS: isNBS,
      isEmail: data.isEmail,
      isCustomerId: data.isCustomerId,
      status: 'comment',
    })
      .then((res) => {
        toast.success(res.data)

        getQuotes()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setInProgress(false)
        setShowComment(false)
      })
  }

  const handleApprove = (row: any) => {
    setActionModalsShow({ approve: true, reject: false })
    setApproveQuoteId(row?.dbkey)
    setApproveCustomerId(row?.customerId)
    setQuoteNumber(row?.quoteNumber)
  }

  const handleUpdatePayment = (data: QuoteModel) => {
    setUpdateQuoteId(data.dbkey)
    setUpdateCustomerId(data?.customerId)

    setQuoteAction(data.paymentOptions)
    setShowOptionsModal(true)
  }

  const handleHide = () => {
    setShowOptionsModal(false)
  }

  const handleCreate = (values: any) => {
    setUpdateStatus(false)

    updateQuotePaymentOption(updateQuoteId, values)
      .then((res) => {
        toast.success(res.data)
        getQuotes()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setUpdateStatus(true)
        setQuoteAction([])
        setShowOptionsModal(false)
      })
  }

  const handleFilter = (filters: any) => {
    const { selectedCustomers, isReset } = filters

    if (isReset === true) {
      setFilter(initialFilter)
      setPage(1)
    } else {
      setFilter(filters)
      setPage(1)

      let filterIds: string[] = []

      selectedCustomers.forEach((customer: Customer) => {
        filterIds.push(customer.id)
      })

      filterIds = isNBS && filterIds.length === 0 ? ['nbs'] : filterIds

      setNbsCustomerId(filterIds)
    }
  }

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const handleApproveSubmit = (values: any) => {
    setApproveStatus(false)

    approveQuote(approveQuoteId, values)
      .then(() => {
        getQuotes()
        toast.success('Sales Order was approved Successfully. Your NBS Rep has been notified!')
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setApproveStatus(true)
        hideActionModals()
      })
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(auth.user?.type))
  }, [props.customerIds, auth])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPage(page)
    setPerPage(newPerPage)
  }

  const handleSort = (column: any, sortDirection: any) => {
    setSortColumn(column.sortField)
    setSortDir(sortDirection)
  }

  useEffect(() => {
    setQuotes(props.salesQuotes)
    setTotalRows(props.totals)
  }, [props.totals, props.salesQuotes])

  useEffect(() => {
    if (isNBS) {
      if (quoteNumberSlug === null) setQuoteNumberSlug(auth.user?.quotePrefix)
    } else {
      setQuoteNumberSlug('')
    }
  }, [])

  useEffect(() => {
    setPage(1)
  }, [slug])

  useEffect(() => {
    if (quoteNumberSlug === auth.user?.quotePrefix) {
      props.setQuoteNumberSlug(quoteNumberSlug)
    } else {
      props.setQuoteNumberSlug('')
    }
  }, [quoteNumberSlug])

  useEffect(() => {
    getQuotes()
  }, [page, perPage, slug, quoteNumberSlug, sortColumn, sortDir, filter, props.customerIds])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Sales Quotes</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1 me-3'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Sales Quotes'
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
            </div>
            {isNBS && (
              <div className='d-flex align-items-center position-relative my-1'>
                <KTSVG
                  path='/media/icons/duotone/General/Search.svg'
                  className='svg-icon-1 position-absolute ms-6'
                />
                <input
                  type='text'
                  data-kt-customer-table-filter='search'
                  className='form-control form-control-solid w-250px ps-15 border-0'
                  placeholder='Search Quote Number'
                  value={quoteNumberSlug || ''}
                  onChange={(e: any) => setQuoteNumberSlug(e.target.value)}
                />
              </div>
            )}
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <div className='filter-dropdown ms-3'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown
                  handleFilter={handleFilter}
                  handleCancelCustomer={handleCancelCustomer}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 service-quotes-body'>
          <DataTableBase
            columns={columns}
            data={quotes}
            progressPending={props.loading}
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            sortFunction={customQuotesSort}
            onSort={handleSort}
            sortServer
            persistTableHead
            pagination
          />
        </Card.Body>
      </Card>
      <BaseModal
        id='service_quote_modal'
        title='Reject Quote'
        content='Are you sure you would like to reject this quote?'
        isComment={true}
        okText='Yes'
        cancelText='No'
        show={show}
        loading={inProgress}
        handleOk={handleReject}
        handleCancel={() => setShow(false)}
      />
      <BaseModal
        id='service_quote_comment_modal'
        title='Add Comment'
        content='Are you sure you would like to add comment to this quote?'
        isComment={true}
        okText='Yes'
        cancelText='No'
        isNBS={isNBS}
        email={commentEmail}
        customerId={commentCustomerId}
        show={showComment}
        loading={inProgress}
        handleOk={handleComment}
        handleCancel={() => setShowComment(false)}
      />
      <ApproveModal
        submit={handleApproveSubmit}
        status={approveStatus}
        show={actionModalsShow.approve}
        {...modalProps}
      />
      <AttachmentModal
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        data={attachment}
        quoteId={attachQuoteId}
        getQuotes={getQuotes}
      />
      <UpdatePaymentOption
        submit={handleCreate}
        status={updateStatus}
        show={showOptionsModal}
        onHide={handleHide}
        actions={quoteAction}
        orderId={updateQuoteId}
        customerId={updateCustomerId}
        showConfirmAlert={showConfirmAlert}
      />
      {confirmAlert.show && (
        <ConfirmModal
          content={confirmAlert.content}
          yesText='Confirm'
          noText='Cancel'
          selectYes={() => {
            confirmAlert.onConfirm()
            setConfirmAlert({ ...confirmAlert, show: false })
          }}
          selectNo={() => setConfirmAlert({ ...confirmAlert, show: false })}
        />
      )}
    </>
  )
}

export default connector(SalesQuotesPage)
