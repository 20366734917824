import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../../helper/response.helper'
import { BillingCompany } from '../../../../types'
import { createBillingCompany, updateBillingCompany } from '../BillingCompanyCRUD'
import Table from './Table'

type Props = {
  type: string
  currentBillingCompany: BillingCompany
  customerKey: number
  show: boolean
  onHide: any
  submit: any
  fetchBillingCompanies : () => void
  handleChangeCompanyId: (companyId: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ConfirmBillingCompanyModal: React.FC<Props> = (props) => {
  const { type, currentBillingCompany, show, onHide, submit } = props
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const headers = [
    'Company ID',
    'Company Name',
    'Billing Email',
    'Billing Phone',
    'Fax Number',
    'Address Line',
    'City',
    'State',
    'Zip Code',
  ]

  const handleSubmit = () => {
    const _actionMethod = type === 'edit' ? updateBillingCompany : createBillingCompany

    setIsSubmit(true)

    _actionMethod(currentBillingCompany)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          onHide(false)
          toast.success(response.data.message)
          submit()
          props.fetchBillingCompanies()
          var companyInfo = response.data.billingCompany
          props.handleChangeCompanyId(response.data.billingCompany.BillingCompanyID, (field, value, shouldValidate) => {
            // Assuming you have access to a setFieldValue function
            // Set the field value to the new value
            props.setFieldValue("customerId", companyInfo.BillingCompanyID, false);
          });
        } else {
          toast.error(response.data.message)
          onHide()
        }
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => setIsSubmit(false))
  }

  return (
    <Modal
      size='lg'
      show={show}
      dialogClassName='w-50'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => onHide(true)}
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>{`${type === 'edit' ? 'Edit' : 'Add'} Billing Company`}</h2>
            <span className='text-hover-primary cursor-pointer' onClick={() => onHide(true)}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <p className='fw-bold fs-6 mb-2 text-center'>
              You are about to {type} a billing company with the below data. Please verify and
              select
              <b> "Submit"</b> to {type} your billing company list or <b>"Discard"</b> to cancel.
            </p>
          </div>
          <div className='fv-row mb-7 fv-plugins-icon-container max-height-60 scroll-y'>
            <Table headers={headers} row={currentBillingCompany} />
          </div>
        </div>
        <div className='text-center pt-15'>
          <button type='button' className='btn btn-light me-3' onClick={() => onHide(true)}>
            Discard
          </button>
          <button
            type='submit'
            className={`btn ${isSubmit ? 'btn-light' : 'btn-primary'}`}
            onClick={handleSubmit}
          >
            Submit
            {isSubmit && (
              <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { ConfirmBillingCompanyModal }
