/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */

import React, { FC, useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { CalibrationReportModel, Customer, ICalibrationReportAttachment, fileType } from '../../../types'
import { RootState } from '../../../setup'
import { TableColumn } from 'react-data-table-component'
import { ActionDropDown } from './component/ActionDropDown'
import moment from 'moment'
import DataTableBase from '../../components/DataTableBase'
import FilterDropDown from '../../modules/calibration/FilterDropDown'
import * as calibrationReportsRedux from './CalibrationReportsRedux'
import * as levelHelper from '../../../helper/level.helper'
import { Badge, Button, Dialog } from '@material-ui/core'
import AddCalReportModal from './component/AddCalReportModal'
import { getAttachment, getOtherCalReportAttachments } from '../other-cal-reports/OtherCalReportsCrud'
import { getErrorMessage } from '../../../helper'
import toast from 'react-hot-toast'
import { AttachmentModal } from '../service-quotes/components/AttachmentModal'
import { CalibrationAttachmentModal } from './component/CalibrationAttachmentModal'
interface ReduxStateValueProps {
  calibrationReports: CalibrationReportModel[]
  loading: boolean
  customerIds: any
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  calibrationReports: state.calibrationReports.calibrationReports,
  loading: state.calibrationReports.loading,
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, { ...calibrationReportsRedux.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type CalibrationReportsProps = ReduxStateValueProps & PropsFromRedux

const CalibrationReportsPage: FC<CalibrationReportsProps> = (props) => {
  const { getCalibrationReports, calibrationReports, customerIds, auth, loading } = props
  const initialFilter = {
    from: '',
    to: '',
    isReset: false,
  }

  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [nbsCustomerIds, setNbsCustomerIds] = useState<string[]>(['nbs'])
  const [nbsCustomerNames, setNbsCustomerNames] = useState<string[]>(['nbs'])
  // const [reportsData, setReportsData] = useState<any>([])
  const [open, setOpen] = useState<boolean>(false)
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)
  const [attachQuoteId, setAttachQuoteId] = useState<number>(0)
  const [attachment, setAttachment] = useState<any>([])
  const [editData, setEditData] = useState<any>([])
  const [type, setType] = useState<'add' | 'edit'>('add')
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [fileInfo, setFileInfo] = useState<CalibrationReportModel>();
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>
  const dispatch = useDispatch()
  const handleFilter = (filters: any) => {
    const { selectedCustomers, from, to, types, isReset } = filters

    let filterIds: string[] = isNBS ? ['nbs'] : []
    let filterNames: string[] = []

    if (!isReset) {
      selectedCustomers.forEach((customer: Customer) => {
        filterIds.push(customer.id)
        filterNames.push(customer.name ?? '')
      })
   
      filterIds = isNBS && filterIds.length === 0 ? ['nbs'] : filterIds
      setNbsCustomerIds(filterIds)
      setNbsCustomerNames(filterNames)
    } else {
      filterIds = customerIds
    }
    
    getCalibrationReports({
      customerIds: isNBS ? filterIds : filterIds,
      // customerNames: filterNames,
      from,
      to,
      types,
    })
  }

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
      setOpenFilter(false)
    }, 200)
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(auth.user?.type))
    const { from, to } = initialFilter
    getCalibrationReports({
      customerIds: levelHelper.isNBS(props.auth.user?.type) ? nbsCustomerIds : customerIds,
      nbsCustomerNames,
      from,
      to,
    })
  }, [getCalibrationReports, customerIds, auth])


  const reportsData = useMemo(() => {
    const filterData = calibrationReports.filter((item: CalibrationReportModel) => {
      return (
        (item.customerid && item.customerid.includes(keyword)) ||
        (item.customer && item.customer.includes(keyword)) ||
        (item.manufacturer && item.manufacturer.includes(keyword)) ||
        (item.mfg && item.mfg.includes(keyword)) ||
        (item.technician_fullname && item.technician_fullname.includes(keyword)) ||
        (item.serial && item.serial.includes(keyword)) ||
        (item.model && item.model.includes(keyword))
      )
    })
    return filterData
  }, [calibrationReports, keyword])

  const handleClose = () => {
    setOpen(false)
  }

  const handleAttachmentModal = useCallback((data: CalibrationReportModel) => {
    setShowAttachmentModal(true)
    setAttachQuoteId(data.dbkey)
    getOtherCalReportAttachments({ dateofcal: data.dateofcal, customerid: data.customerid }).then(
      (res) => {
        let attachments: fileType[] = []

        res.data.map((item: ICalibrationReportAttachment, index1: number) => {
          JSON.parse(item?.attachment as string).map((name: string, index2: number) => {
            attachments.push({
              id: `${index1}-${index2}`,
              name: name,
              size: 0,
              type: 'old',
              checked: false,
              cal_date: item.calibration_date,
              cal_type: item.calibration_type,
              author: item.technician_fullname,
              quoteId: item.id,
            })
          })
        })

        setAttachment(attachments)
      }
    )
    // getAttachment(dbKey)
    //   .then((res) => {
    // if (data.otherAttachment.length > 0) {
    //   data.otherAttachment.map((f:ICalibrationReportAttachment,index:number) => {
    //      JSON.parse(f?.attachment as string).forEach((name: string) => {
    //   attachments.push({ name: name, size: 0, type: 'old', checked: false,cal_date:f.calibration_date,cal_type:f.calibration_type,author:f.dbkey_tech.toString() })
    // })
    //   })
    // }
    // console.log('attachment==', attachments)
    // setAttachment(attachments)

    setFileInfo(data)
    // })
    // .catch((error) => {
    //   const errMsg = getErrorMessage(error)
    //   toast.error(errMsg)
    // })
  }, [])

  const handleEdit = (data: any) => {
    setType('edit')
    setOpen(true)
    setEditData(data)
  }

   const handleCount = (row: CalibrationReportModel) => {
    let count =  JSON.parse(row.attachment as string).length 
    //  if (row.otherAttachment.length > 0) { 
    //    row.otherAttachment.map((f: ICalibrationReportAttachment, index: number) => {
    //      count += JSON.parse(f.attachment).length
    //    })
    //  }
     return count
  
}
  const columns: TableColumn<CalibrationReportModel>[] = useMemo(
    () => [
      {
        name: 'Actions',
        cell: (row) => (
          <Badge
            variant='standard'
            badgeContent={row.count}
            color='secondary'
          >
            <ActionDropDown
              isNBS={isNBS}
              data={row}
              handleAttachmentModal={handleAttachmentModal}
              handleEdit={handleEdit}
            />
          </Badge>
        ),
        width: '200px',
        style: {
          minWidth: '200px !important',
          maxWidth: '200px !important',
        },
      },

      {
        name: 'Month Calibrated',
        selector: (row: CalibrationReportModel) => row.dateofcal,
        cell: (row: CalibrationReportModel) => <span>{moment(row.dateofcal).format('MMM - YYYY')}</span>,
        sortable: true,
        width: '20%',
        style: {
          minWidth: '20% !important',
          maxWidth: '20% !important',
        },
      },
      {
        name: 'Customer ID',
        selector: (row: CalibrationReportModel) => row.customerid,
        cell: (row: CalibrationReportModel) => <span>{row.customerid}</span>,
        sortable: true,
        width: '20%',
        style: {
          minWidth: '20% !important',
          maxWidth: '20% !important',
        },
      },
      {
        name: 'Customer Name',
        selector: (row: CalibrationReportModel) => row.customer,
        cell: (row: CalibrationReportModel) => <span>{row.customer}</span>,
        sortable: true,
        width: '30%',
        style: {
          minWidth: '30% !important',
          maxWidth: '30% !important',
          justifyContent: 'flex-start !important',
        },
      },
    ],
    [isNBS, reportsData]
  )

  return (
    <>
      <PageTitle breadcrumbs={[]}>Calibration Reports</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Calibration Reports'
                value={keyword}
                onChange={(e: any) => setKeyword(e.target.value)}
              />
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <div className='filter-dropdown ms-3'>
                {isNBS && (
                  <Button
                    style={{ marginRight: '8px' }}
                    size='large'
                    variant='outlined'
                    color='primary'
                    startIcon={
                      <KTSVG
                        path='/media/icons/duotone/Interface/Plus-Square.svg'
                        className='svg-icon-2 text-light'
                      />
                    }
                    onClick={() => {
                      setType('add')
                      setOpen(true)
                    }}
                  >
                    Upload Reports
                  </Button>
                )}
                <button
                  type='button'
                  onClick={() => {
                    setOpenFilter(true)
                  }}
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown
                  open={openFilter}
                  handleFilter={handleFilter}
                  handleCancelCustomer={handleCancelCustomer}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 calibration-reports-body'>
          <DataTableBase
            pagination
            columns={columns}
            data={reportsData}
            progressPending={loading}
          />
        </Card.Body>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
        <AddCalReportModal open={open} handleClose={handleClose} data={editData} type={type} />
      </Dialog>
      <CalibrationAttachmentModal
        fileInfo={fileInfo}
        show={showAttachmentModal}
        onHide={() => setShowAttachmentModal(false)}
        data={attachment}
        quoteId={attachQuoteId}
        isNBS={isNBS}
        getQuotes={
          // () => {}
          () => {
            getCalibrationReports({
              customerIds: levelHelper.isNBS(props.auth.user?.type) ? nbsCustomerIds : customerIds,
              nbsCustomerNames,
              from: '',
              to: '',
            })
          }
        }
      />
    </>
  )
}

export default connector(CalibrationReportsPage)
