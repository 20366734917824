/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import moment from 'moment'
import toast from 'react-hot-toast'
import parse from 'html-react-parser'
import { Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap-v5'
import FilterDropDown from './_dropdown/FilterDropDown'
import BaseModal from '../../components/_modal/BaseModal'
import { BlogModel, UserModel } from '../../../types'
import { KTSVG } from '../../../_metronic/helpers'
import { getErrorMessage } from '../../../helper/response.helper'
import { extractFileName } from '../../../helper/extractFileName.helper'
import { isImageFile } from '../../../helper/fileType.helper'
import * as blogs from './redux/blogRedux'
import { removeBlog } from './redux/blogCRUD'

import * as levelHelper from '../../../helper/level.helper'

type Props = {
  className: string
  data: BlogModel[]
  user: UserModel | undefined
  loading: boolean
  isNBS: boolean
  handleEdit: (blog: BlogModel | null) => void
}

function CustomToggle(props: any) {
  const activeEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(props.eventKey, () => {
    props.callback && props.callback(props.eventKey)
  })

  const isCurrentEventKey = activeEventKey === props.eventKey

  return (
    <a
      type='button'
      onClick={decoratedOnClick}
      className={clsx('custom-toggle-button text-reset', { collapsed: !isCurrentEventKey })}
    >
      {props.children}
    </a>
  )
}

const BlogList: React.FC<Props> = (props) => {
  const [isSearch, setIsSearch] = useState<boolean | null>(null)
  const [isToday, setIsToday] = useState(false)
  const [slug, setSlug] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [show, setShow] = useState(false)
  const [blog, setBlog] = useState<BlogModel>()
  const dispatch = useDispatch()

  const getBlogs = (filter: any) => {
    dispatch(blogs.actions.getBlogs(filter))
  }

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setIsSearch(true)
      getBlogs({ slug: slug, from: null, to: null })
    }
  }

  const handleDelete = () => {
    setInProgress(true)
    removeBlog(blog?.id)
      .then((res) => {
        toast.success(res.data.message)
        dispatch(blogs.actions.getBlogs({ slug: null, from: null, to: null }))
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setShow(false)
        setInProgress(false)
      })
  }

  return (
    <div className={`card ${props.className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>Whats new at NBS</span>
        </h3>
        <form className='d-flex w-100 position-relative mb-3' autoComplete='off'>
          <div className='d-flex w-auto flex-grow-1'>
            <KTSVG
              path='/media/icons/duotone/General/Search.svg'
              className='svg-icon-1 svg-icon-2 position-absolute top-50 ms-2 translate-middle-y'
            />
            <input
              type='text'
              value={slug}
              onChange={(e: any) => setSlug(e.target.value)}
              onKeyDown={_handleKeyDown}
              className='form-control form-control-flush ps-10 bg-light rounded'
              name='search'
              placeholder='Search...'
            />
          </div>
          <button
            type='button'
            className='btn btn-primary ms-3'
            disabled={isSearch != null && isSearch && props.loading}
            onClick={() => {
              setIsSearch(true)
              getBlogs({ slug: slug, from: null, to: null })
            }}
          >
            {(!props.loading || (props.loading && !isSearch) || isSearch == null) && 'Search'}
            {props.loading && isSearch && isSearch != null && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button'
            className='btn btn-primary ms-3'
            disabled={isSearch != null && !isSearch && props.loading}
            onClick={() => {
              setIsSearch(false)
              setIsToday(!isToday)
              if (isToday) {
                getBlogs({ slug: null, from: null, to: null })
              } else {
                getBlogs({
                  slug: null,
                  from: moment().startOf('day').utc().format('Y-MM-DD HH:mm'),
                  to: moment().endOf('day').utc().format('Y-MM-DD HH:mm'),
                })
              }
            }}
          >
            {(!props.loading || (props.loading && isSearch) || isSearch == null) &&
              (!isToday ? "Today's Posts" : 'Reset Filter')}
            {props.loading && !isSearch && isSearch != null && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <div className='filter-dropdown ms-3'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-light btn-text-dark-50 font-weight-bold btn-hover-bg-light mr-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Filter options'
            >
              <KTSVG path='/media/icons/duotone/Text/Filter.svg' className='svg-icon-2' />
              Filter
            </button>
            <FilterDropDown handleFilter={getBlogs} />
            {/* end::Menu */}
          </div>
        </form>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body overflow-y-scroll  mt-5' style={{maxHeight: "270px", overflowY: "scroll"}}>
        {/* begin::Timeline */}
        <Accordion defaultActiveKey='0'>
          <div className={clsx('timeline-label', { admin: props.isNBS })}>
            {props.data.map((item, i) => {
              return (
                <div className='timeline-item' key={`blog_${i}`}>
                  {/* begin::Label */}
                  <div
                    className={clsx('timeline-label fw-bolder text-gray-800 fs-6', {
                      admin: props.isNBS,
                    })}
                  >
                    {props.isNBS && !levelHelper.isCustomerTechnician(props.user?.level) && (
                      <>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          href='#kt_blog_form_container'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          data-bs-trigger='hover'
                          title='Edit Blog Post'
                          onClick={() => props.handleEdit(item)}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Communication/Write.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-12'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          data-bs-trigger='hover'
                          title='Delete Blog Post'
                          onClick={() => {
                            setBlog(item)
                            setShow(true)
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotone/General/Trash.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </>
                    )}
                    {moment(item.createdAt).format('MM/DD/YY hh:mma')}
                  </div>
                  {/* end::Label */}
                  {/* begin::Badge */}
                  <div className='timeline-badge'>
                    <i className='fa fa-genderless text-success fs-1'></i>
                  </div>
                  {/* end::Badge */}
                  {/* begin::Content */}
                  <div className='timeline-content'>
                    <span className='fw-bolder text-gray-800 ps-3 fs-6'>
                      <CustomToggle eventKey={`blog_${i}`}>{item.title}</CustomToggle>
                    </span>
                    <Accordion.Collapse
                      eventKey={`blog_${i}`}
                      className='ms-8 mt-3 overflow-y-scroll mh-150px'
                    >
                      <>
                        <span>{parse(item.content ?? '')}</span>
                        {isImageFile(extractFileName(item.attachment)) ? (
                          <img src={item.attachment} alt='' />
                        ) : (
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href={item.attachment}
                            className='text-decoration-underline'
                          >
                            {extractFileName(item.attachment)}
                          </a>
                        )}
                      </>
                    </Accordion.Collapse>
                  </div>
                  {/* end::Content */}
                </div>
              )
            })}
          </div>
        </Accordion>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
      <BaseModal
        id='blog_list'
        title='Delete Blog'
        content='Are you sure you want to delete this blog?'
        isComment={false}
        okText='Yes'
        cancelText='No'
        show={show}
        loading={inProgress}
        handleOk={handleDelete}
        handleCancel={() => setShow(false)}
      />
    </div>
  )
}

export default BlogList
