/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import moment from 'moment'

import { ConnectedProps, connect } from 'react-redux'
import { RootState } from '../../../../setup'
import * as serviceQuoteRedux from '../../service-quotes/serviceQuotesRedux'
import * as salesQuoteRedux from '../../sales-quotes/salesQuotesRedux'
import { KTSVG } from '../../../../_metronic/helpers'

import { getErrorMessage } from '../../../../helper'
import { QuoteModel } from '../../../../types'
import BaseModal from '../../../components/_modal/BaseModal'

import ApproveModal from '../../../components/_modal/ApproveDialog'
import {
  approveQuote as approveServiceQuote,
  rejectQuote as rejectServiceQuote,
} from '../../service-quotes/serviceQuotesCrud'
import { approveQuote as approveSalesQuote } from '../../sales-quotes/salesQuotesCrud'

const mapState = (state: RootState) => ({
  ...serviceQuoteRedux.actions,
  ...salesQuoteRedux.actions,

  serviceQuotes: state.serviceQuotes.serviceQuotes,
  salesQuotes: state.salesQuotes.salesQuotes,

  loadingServiceQuotes: state.serviceQuotes.loading,
  loadingSalesQuotes: state.salesQuotes.loading,

  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, {
  ...serviceQuoteRedux.actions,
  ...salesQuoteRedux.actions,
})

type DashboardApprovalQuotesProps = ConnectedProps<typeof connector>

const DashboardApprovalQuotes: FC<DashboardApprovalQuotesProps> = ({
  serviceQuotes,
  salesQuotes,
  loadingServiceQuotes,
  loadingSalesQuotes,
  auth,
  customerIds,
  ...props
}) => {
  const initialActionModalsShow = {
    approve: false,
    reject: false,
  }

  const defaultQuotesFilter = {
    status: ['Active'],
    confirm: ['No'],
    from: '',
    to: moment().utc().format('Y-MM-DD HH:mm'),
    transType: ['Cal_Lab', 'Service', 'Repair'],
    isReset: false,
  }

  const [keywords, setKeywords] = useState('')

  const [actionModalsShow, setActionModalsShow] = useState(initialActionModalsShow)
  const [quoteId, setQuoteId] = useState<number>(0)
  const [customerId, setCustomerId] = useState<string>('')
  const [quoteNumber, setQuoteNumber] = useState<string>('')
  const [approveStatus, setApproveStatus] = useState<boolean>(false)

  const [, setConfirmAlert] = useState({
    show: false,
    content: '',
    onConfirm: () => { },
  })

  const [currentQuote, setCurrentQuote] = useState<QuoteModel>()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false)

  const [quotes, setQuotes] = useState<QuoteModel[]>([])
  const [allQuotes, setAllQuotes] = useState<QuoteModel[]>([])

  const handleApprove = (quote: QuoteModel) => {
    setActionModalsShow({ approve: true, reject: false })
    setCustomerId(quote.customerId)
    setQuoteId(quote.dbkey)
    setQuoteNumber(quote.quoteNumber)
  }

  const handleReject = (data: any) => {
    setInProgress(true)
    rejectServiceQuote({
      comments: data.comment,
      quoteId: currentQuote?.dbkey === undefined ? 0 : currentQuote?.dbkey,
      status: 'reject',
    })
      .then((res) => {
        toast.success(res.data)
        getQuotes()
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setInProgress(false)
        setShowRejectModal(false)
      })
  }

  const getQuotes = () => {
    const quoteFilter = {
      ...defaultQuotesFilter,
      customerIds,
    }

    props.getServiceQuotes(quoteFilter)
    props.getSalesQuotes(quoteFilter)
  }

  const hideActionModals = () => {
    setActionModalsShow(initialActionModalsShow)
  }

  const showConfirmAlert = useCallback((content: string, onConfirm: any) => {
    setConfirmAlert({ show: true, content, onConfirm })
  }, [])

  const modalProps = {
    onHide: hideActionModals,
    quoteNumber,
    quoteId,
    customerId,
    showConfirmAlert,
  }

  const handleApproveSubmit = (values: any) => {
    const quote = allQuotes.find((quote) => quote.dbkey === quoteId)
    const isServiceQuote = quote?.transactionType === 'Service'

    const approveQuote = isServiceQuote ? approveServiceQuote : approveSalesQuote

    setApproveStatus(false)

    approveQuote(quoteId, values)
      .then(() => {
        getQuotes()

        if (isServiceQuote) {
          toast.success('Service Order was approved Successfully. Your NBS Rep has been notified!')
        } else {
          toast.success('Sales Order was approved Successfully. Your NBS Rep has been notified!')
        }
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setApproveStatus(true)
        hideActionModals()
      })
  }

  useEffect(() => {
    setAllQuotes([...serviceQuotes, ...salesQuotes])
  }, [serviceQuotes, salesQuotes])

  useEffect(() => {
    const searchedQuotes = allQuotes.filter((quote: QuoteModel) => {
      if (quote.quoteNumber.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      if (quote.salesRep?.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      if (quote.transactionType.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      if (quote.attention.toLowerCase().includes(keywords.toLowerCase())) {
        return true
      }

      return false
    })

    setQuotes(searchedQuotes)
  }, [keywords, allQuotes])

  useEffect(() => {
    getQuotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {allQuotes.length !== 0 && !loadingServiceQuotes && !loadingSalesQuotes && (
        <>
          <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5 min-h-50px justify-content-start'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  <span className='position-relative'>
                    <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle mt-n2 top-0 start-50 animation-blink'></span>
                    {allQuotes.length}
                  </span>{' '}
                  Quotes Requiring your Approval
                </span>
              </h3>
              <div className='d-flex position-relative'>
                <KTSVG
                  path='/media/icons/duotone/General/Search.svg'
                  className='svg-icon-1 svg-icon-2 position-absolute top-50 ms-2 translate-middle-y mt-0'
                />
                <input
                  type='text'
                  value={keywords}
                  onChange={(e: any) => setKeywords(e.target.value)}
                  className='form-control form-control-flush ps-10 bg-light rounded'
                  name='search'
                  placeholder='Search...'
                />
              </div>
            </div>
            {/* end::Header */}
            <div className='card-body overflow-y-scroll mh-500px mt-5'>
              <div className='row g-6 g-xl-9'>
                {quotes.map((quote: any) => {
                  return (
                    <div className='col-md-6 col-xl-6 col-lg-4 mt-2' key={quote.dbkey}>
                      <div className='card border border-1 border-gray-300'>
                        {/* begin::Header */}
                        <div className='card-header border-0 py-4 px-4 bg-light'>
                          <div className='d-flex'>
                            <div className='me-4'>
                              <div className='text-dark fw-bolder fs-6'>Quote Placed</div>
                              <div>{moment(quote.createdAt).format('MMMM DD, YYYY')}</div>
                            </div>
                            <div className='me-4'>
                              <div className='text-dark fw-bolder fs-6'>TOTAL</div>
                              <div>${quote.total ?? 0}</div>
                            </div>
                            <div className='me-4'>
                              <div className='text-dark fw-bolder fs-6'>Your Rep</div>
                              <div style={{ maxWidth: '90px' }} className='text-truncate'>
                                {quote.salesRep}
                              </div>
                            </div>
                            <div className=''>
                              <div className='text-dark fw-bolder fs-6'>Type</div>
                              <div>{quote.transactionType}</div>
                            </div>
                          </div>
                          <div>
                            <div className='text-dark fw-bolder fs-6'>
                              Quote# {quote.quoteNumber}
                            </div>
                            <div className='text-end'>
                              <a
                                href={`${process.env.REACT_APP_NBS_ONLINE_URL}processing_pdf.php?dbkey_quote_b=${quote.dbkey}&order_type=quote_portal&pdf=y`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                View Details
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* end::Header */}

                        {/* begin::Content */}
                        <div className='card-body p-3 px-9'>
                          <div className='row'>
                            <div className='col-md-7'>
                              {quote.items.length !== 0 && (
                                <div className='text-dark fw-bolder fs-6 pt-7'>
                                  Itemized Details
                                </div>
                              )}
                              {quote.items.map((item: any) => {
                                return (
                                  <div className='row' key={`${quote.dbkey}${item.dbkey}`}>
                                    <div className='col-md-3 mt-5'>
                                      <div>{Number(item.quantity) ? item.quantity : ''}</div>
                                    </div>
                                    <div className='col-md-3 mt-5'>
                                      <div>{item.description}</div>
                                    </div>
                                    <div className='col-md-3 mt-5'>
                                      <div>{item.part_number}</div>
                                    </div>
                                    <div className='col-md-3 mt-5'>
                                      <div>
                                        {Number(item.unit_price) ? '$' + item.unit_price : ''}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            <div className='col-md-5 text-end my-5 justify-content-end'>
                              <button
                                type='button'
                                className='btn btn-success ms-3 px-3 py-3'
                                onClick={() => handleApprove(quote)}
                              >
                                Approve Now
                              </button>
                              <button
                                type='button'
                                className='btn btn-danger ms-3 px-3 py-3'
                                onClick={() => {
                                  setShowRejectModal(true)
                                  setCurrentQuote(quote)
                                }}
                              >
                                Reject Quote
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* end::Content */}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <ApproveModal
            submit={handleApproveSubmit}
            status={approveStatus}
            show={actionModalsShow.approve}
            {...modalProps}
          />

          <BaseModal
            id='quote_reject_modal'
            title='Reject Quote'
            content='Are you sure you would like to reject this quote?'
            isComment={true}
            okText='Yes'
            cancelText='No'
            show={showRejectModal}
            loading={inProgress}
            handleOk={handleReject}
            handleCancel={() => setShowRejectModal(false)}
          />
        </>
      )}
    </>
  )
}

export default connector(DashboardApprovalQuotes)
