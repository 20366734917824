/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { FC, useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import { TableColumn } from 'react-data-table-component'
import {
  UserModel,
  CustomerModel,
  Customer,
  BillingCompany,
  CustomersResponse,
} from '../../../types'
import { KTSVG } from '../../../_metronic/helpers'
import { RootState } from '../../../setup'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { ActionDropDown } from './component/ActionDropDown'
import { ConfirmModal } from '../../components/_modal/ConfirmModal'
import { EditCustomerModal } from './component/EditCustomerModal'
import { AddCustomerModal } from './component/AddCustomerModal'
import { ViewCustomerModal } from './component/ViewCustomerModal'
import DataTableBase from '../../components/DataTableBase'
import FilterDropDown from './component/FilterDropDown'
import * as customersRedux from './CustomersRedux'
import { isNBS } from '../../../helper/level.helper'
import { getCustomerShippingID, getCustomersWithCompanyID } from './CustomersCRUD'
import { STATES } from '../../../data/constants'
import * as billingActions from '../billing-companies/BillingCompanyRedux'
import { AnyObject } from 'yup/lib/types'
import { getBillingCompaniesWithSpecCol } from '../billing-companies/BillingCompanyCRUD'

interface ReduxStateValueProps {
  user: UserModel | undefined
  nbsCustomers: CustomersResponse
  loading: boolean
  userId?: number | undefined
  auth: IAuthState & PersistPartial
  companyId?: string
  getCustomers?: (customers: CustomerModel[]) => void
  // billingCompanies: BillingCompany[]
}

const mapState = (state: RootState) => ({
  user: state.auth?.user,
  nbsCustomers: state.nbsCustomers.nbsCustomers.data ?? [],
  pagination: state.nbsCustomers.nbsCustomers.pagination,
  loading: state.nbsCustomers.loading,
  customerIds: state.auth.activeCustomerIds,
  auth: state.auth,
  // billingCompanies: state.billingCompany.billingCompanies,
})

const connector = connect(mapState, { ...customersRedux.actions, ...billingActions.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type CustomersProps = ReduxStateValueProps & PropsFromRedux

const CustomersPage: FC<CustomersProps> = (props) => {
  const {
    getNBSCustomers,
    nbsCustomers,
    loading,
    userId,
    customerIds,
    companyId,
    getCustomers,
    // billingCompanies,
    getBillingCompanies,
    pagination,
  } = props
  const initialFilter = {
    status: ['active'],
    isReset: false,
  }
  const [perPage, setPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [companies, setCompanies] = useState<Partial<BillingCompany[]>>([])
  const [slug, setSlug] = useState<string>('')
  const [editCustomer, setEditCustomer] = useState<CustomerModel>(nbsCustomers[0])
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showViewModal, setShowViewModal] = useState<boolean>(false)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [idShipping, setIDShipping] = useState<string>('')
  const [customerID, setCustomerID] = useState<string>('')
  const [column, setColumn] = useState('')
  const [sort, setSort] = useState('')
  const [customers, setCustomers] = useState<CustomerModel[]>([])

  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const handleEdit = useCallback((data: CustomerModel) => {
    setShowModal(true)
    setEditCustomer(data)
  }, [])

  const handleView = useCallback((data: CustomerModel) => {
    setShowViewModal(true)
    setEditCustomer(data)
  }, [])

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const handleCancelCompany = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const handleCloseModal = useCallback((isShowConfirm: boolean | undefined) => {
    if (isShowConfirm === true && !companyId) setShowConfirm(true)
    else {
      setShowModal(false)
      setShowAddModal(false)
      setShowViewModal(false)
    }
  }, [])

  const handleConfirmCancel = () => {
    setShowModal(false)
    setShowConfirm(false)
    setShowAddModal(false)
  }

  const handleConfirmReturn = () => {
    setShowConfirm(false)
  }

  const columns: TableColumn<CustomerModel>[] = useMemo(
    () =>
      companyId
        ? [
            {
              name: 'Actions',
              cell: (row) => (
                <ActionDropDown
                  data={row}
                  handleEdit={handleEdit}
                  handleView={handleView}
                  canEdit={isNBS(props.user?.type)}
                />
              ),
              width: '100px',
              style: {
                minWidth: '100px !important',
                maxWidth: '100px !important',
              },
            },
            {
              id: 'CustomerID',
              name: 'Customer ID',
              selector: (row) => row.customerId,
              sortable: true,
              width: '18%',
              style: {
                minWidth: '18% !important',
                maxWidth: '18% !important',
              },
            },
            {
              id: 'ShipCompanyName',
              name: 'Company Name',
              selector: (row) => row.shipCompanyName ?? '',
              sortable: true,
              width: '20%',
              style: {
                minWidth: '20% !important',
                maxWidth: '20% !important',
              },
            },
            {
              id: 'FirstName',
              name: 'Ship to Contact',
              selector: (row) => `${row.contactFirstName ?? ''} ${row.contactLastName ?? ''}`,
              sortable: true,
              width: '20%',
              style: {
                minWidth: '20% !important',
                maxWidth: '20% !important',
              },
            },
            {
              id: 'Phonenum',
              name: 'Ship To Phone Number',
              selector: (row) => row.shipPhoneNumber ?? '',
              sortable: true,
              width: '20%',
              style: {
                minWidth: '20% !important',
                maxWidth: '20% !important',
              },
            },
            // {
            //   id: 'ShipCity',
            //   name: 'Ship To City',
            //   selector: (row) => `${row.shipCity ?? ''}`,
            //   sortable: true,
            //   width: '15%',
            //   style: {
            //     minWidth: '15% !important',
            //     maxWidth: '15% !important',
            //   },
            // },
            // {
            //   id: 'ShipState',
            //   name: 'Ship To State',
            //   selector: (row) => `${row.shipState ?? ''}`,
            //   sortable: true,
            //   width: 'calc(25% - 170px)',
            //   style: {
            //     minWidth: 'calc(25% - 170px) !important',
            //     maxWidth: 'calc(25% - 170px) !important',
            //   },
            // },
            // {
            //   name: 'Active',
            //   selector: (row) => row.status,
            //   sortable: true,
            //   width: '70px',
            //   style: {
            //     minWidth: '70px !important',
            //     maxWidth: '70px !important',
            //     paddingRight: '20px !important',
            //   },
            // },
          ]
        : [
            {
              name: 'Actions',
              cell: (row) => (
                <ActionDropDown
                  data={row}
                  handleEdit={handleEdit}
                  handleView={handleView}
                  canEdit={isNBS(props.user?.type)}
                />
              ),
              width: '100px',
              style: {
                minWidth: '100px !important',
                maxWidth: '100px !important',
              },
            },
            {
              id: 'CustomerID',
              name: 'Customer ID',
              selector: (row) => row.customerId,
              sortable: true,
              width: '10%',
              style: {
                minWidth: '10% !important',
                maxWidth: '10% !important',
              },
            },
            {
              id: 'ShipCompanyName',
              name: 'Company Name',
              selector: (row) => row.shipCompanyName ?? '',
              sortable: true,
              width: '15%',
              style: {
                minWidth: '15% !important',
                maxWidth: '15% !important',
              },
            },
            {
              id: 'FirstName',
              name: 'Ship to Contact',
              selector: (row) => `${row.contactFirstName ?? ''} ${row.contactLastName ?? ''}`,
              sortable: true,
              width: '15%',
              style: {
                minWidth: '15% !important',
                maxWidth: '15% !important',
              },
            },
            {
              id: 'Phonenum',
              name: 'Ship To Phone Number',
              selector: (row) => row.shipPhoneNumber ?? '',
              sortable: true,
              width: '15%',
              style: {
                minWidth: '15% !important',
                maxWidth: '15% !important',
              },
            },
            {
              id: 'ShipCity',
              name: 'Ship To City',
              selector: (row) => `${row.shipCity ?? ''}`,
              sortable: true,
              width: '15%',
              style: {
                minWidth: '15% !important',
                maxWidth: '15% !important',
              },
            },
            {
              id: 'ShipState',
              name: 'Ship To State',
              selector: (row) => `${row.shipState ?? ''}`,
              sortable: true,
              width: 'calc(25% - 170px)',
              style: {
                minWidth: 'calc(25% - 170px) !important',
                maxWidth: 'calc(25% - 170px) !important',
              },
            },
            {
              name: 'Active',
              selector: (row) => row.status,
              sortable: true,
              width: '70px',
              style: {
                minWidth: '70px !important',
                maxWidth: '70px !important',
                paddingRight: '20px !important',
              },
            },
          ],
    [handleEdit, handleView, companyId]
  )

  useEffect(() => {
    getNBSCustomers(customerIds, page, perPage, slug, column, sort)
  }, [getNBSCustomers, customerIds, perPage, page, slug, column, sort])

  useEffect(() => {
    getBillingCompaniesWithSpecCol().then((res) => {
      setCompanies(res.data)
    })
  }, [])

  useEffect(() => {
    if (companyId && getCustomers) {
      getCustomersWithCompanyID(companyId)
        .then((res) => {
          setCustomers(res.data)
          getCustomers(res.data)
        })
        .catch((err) => console.log(err))
    }
  }, [companyId, getCustomers])

  const handleFilter = (filters: any) => {
    const { selectedCustomers, selectedCompanies, status, isReset } = filters
    if (isReset === true) {
      getNBSCustomers(customerIds, page, perPage, slug, column, sort, initialFilter.status)
    } else {
      let filteredCustomerIds: string[] = selectedCustomers.map((customer: Customer) => customer.id)
      if (customerIds !== undefined) {
        customerIds.map((customer: string) => {
          filteredCustomerIds.push(customer)
        })
      }
      const filteredCompanyIds: string[] = selectedCompanies.map(
        (customer: BillingCompany) => customer.BillingCompanyID
      )


      getNBSCustomers(
        filteredCustomerIds,
        page,
        perPage,
        slug,
        column,
        sort,
        status,
        filteredCompanyIds
      )
    }
  }

  const handleSort = (column: any, direction: any) => {
    setColumn(column.id)
    setSort(direction)
  }
  const fetchBillingCompanies = () => {
    getBillingCompaniesWithSpecCol().then((res) => {
      setCompanies(res.data);
    }).catch((err) => console.error("Fetching billing companies failed", err));
  };
  return (
    <>
      {companyId ? (
        <>
          <DataTableBase
            columns={columns}
            data={customers}
            progressPending={loading}
            // pagination
            // onSort={handleSort}
            // paginationTotalRows={pagination?.total}
            // paginationPerPage={perPage}
            // paginationDefaultPage={1}
            // paginationServer
            // sortServer
            // onChangePage={(m, n) => {
            //   setPage(m)
            // }}
            // onChangeRowsPerPage={(e, n) => {
            //   setPerPage(e)
            // }}
          />
        </>
      ) : (
        <>
          <PageTitle breadcrumbs={[]}>Customers</PageTitle>
          <Card className='min-vh-100'>
            <Card.Header className='border-0 pt-6'>
              <Card.Title>
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTSVG
                    path='/media/icons/duotone/General/Search.svg'
                    className='svg-icon-1 position-absolute ms-6'
                  />
                  <input
                    type='text'
                    data-kt-customer-table-filter='search'
                    className='form-control form-control-solid w-250px ps-15 border-0'
                    placeholder='Search'
                    value={slug}
                    onChange={(e: any) => setSlug(e.target.value)}
                  />
                </div>
              </Card.Title>
              <div className='card-toolbar'>
                <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
                  {isNBS(props.user?.type) && (
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => {
                        getCustomerShippingID()
                          .then((res) => {
                            setIDShipping(res.data[0])
                            setCustomerID(res.data[1])
                            setShowAddModal(true)
                          })
                          .catch((err) => console.log(err))
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Navigation/Plus.svg'
                        className='svg-icon-2'
                      />
                      New Customer
                    </button>
                  )}
                  <div className='filter-dropdown ms-3'>
                    <button
                      type='button'
                      className='btn btn-light-primary me-3'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      data-bs-trigger='hover'
                      title='Filter options'
                      ref={filterRef}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Text/Filter.svg'
                        className='svg-icon-2 text-light'
                      />
                      Filter
                    </button>
                    <FilterDropDown
                      companies={companies as BillingCompany[]}
                      handleFilter={handleFilter}
                      handleCancelCustomer={handleCancelCustomer}
                      handleCancelCompany={handleCancelCompany}
                      initialFilterStatus={initialFilter.status}
                    />
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className='pt-0 service-quotes-body'>
              <DataTableBase
                columns={columns}
                data={nbsCustomers}
                progressPending={loading}
                pagination
                onSort={handleSort}
                paginationTotalRows={pagination?.total}
                paginationPerPage={perPage}
                paginationDefaultPage={1}
                paginationServer
                sortServer
                onChangePage={(m, n) => {
                  setPage(m)
                }}
                onChangeRowsPerPage={(e, n) => {
                  setPerPage(e)
                }}
              />
            </Card.Body>
          </Card>
          {showConfirm && (
            <ConfirmModal
              content='Are you sure you would like to cancel?'
              yesText='Yes, cancel it!'
              noText='No, return'
              selectYes={handleConfirmCancel}
              selectNo={handleConfirmReturn}
            />
          )}
        </>
      )}
      <EditCustomerModal
        billingCompanies={companies as BillingCompany[]}
        show={showModal}
        customer={editCustomer}
        onHide={handleCloseModal}
        userId={userId}
        fetchBillingCompanies={fetchBillingCompanies}
        getCustomers={() => {
          if (companyId && getCustomers) {
            getCustomersWithCompanyID(companyId)
              .then((res) => {
                setCustomers(res.data)
                getCustomers(res.data)
              })
              .catch((err) => console.log(err))
          } else {
            getNBSCustomers(customerIds, page, perPage, slug, column, sort)
          }
        }}
      />
      <AddCustomerModal
        billingCompanies={companies as BillingCompany[]}
        show={showAddModal}
        onHide={handleCloseModal}
        idShipping={idShipping}
        customerID={customerID}
        userId={userId}
        fetchBillingCompanies={fetchBillingCompanies}
        getCustomers={() => getNBSCustomers(customerIds, page, perPage, slug, column, sort)}
      />
      <ViewCustomerModal show={showViewModal} customer={editCustomer} onHide={handleCloseModal} />
    </>
  )
}

export default connector(CustomersPage)
