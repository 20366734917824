import React, { useState, useEffect, useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../_metronic/helpers'
import { REQUEST_SUPPORT_TITLE } from '../../../data/constants'
import { SelectDropDown } from '../SelectDropDown'
import { Formik, Field } from 'formik'
import { CONFIRM_SUBMIT } from '../../../data/constants'
import { validLink } from '../../../helper'
import * as Yup from 'yup'

const CustomerEditSchema = Yup.object().shape({
  subject: Yup.string().required('Subejct is required'),
  product: Yup.string().required('Comment is required'),
})

const initialValues = {
  subject: '',
  product: '',
  link: '',
  description: '',
  attachment: '',
}

const RequestSupport = ({ submit, status, show, onHide, showConfirmAlert }) => {
  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [allFiles, setAllFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])

  const user = useSelector(({ auth }) => auth.user, shallowEqual)
  const products = ['NBS Calibrations Portal', 'NBS Online']

  const inputFileRef = useRef()

  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handleSubmit = (values) => {
    setLoading(true)
    submit(values)
  }

  const handleCancelFile = (name) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== name))

    let removedFiles = []

    for (let file of allFiles) {
      if (file.name !== name) removedFiles.push(file)
    }

    setAllFiles(removedFiles)
    return false
  }

  const handleChangeFiles = (files) => {
    let fetchFiles = []

    for (let file of files) fetchFiles.push({ name: file.name, size: file.size })

    setSelectedFiles([...selectedFiles, ...fetchFiles])
  }

  useEffect(() => {
    if (status) setLoading(false)
  }, [status])

  useEffect(() => {
    setFirstName(user.firstname)
    setLastName(user.lastname)
    setSelectedFiles([])
  }, [user, show])

  return (
    <Modal
      size='lg'
      show={show}
      dialogClassName='w-50'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='pb-0 border-0'>
        <Modal.Title className='w-100 text-end' id='contained-modal-title-vcenter'>
          <span className='text-hover-primary cursor-pointer' onClick={onHide}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='scroll-y px-10 px-lg-15 pt-0 pb-15'>
        <Formik
          validationSchema={CustomerEditSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, setFieldValue, errors, touched, values }) => (
            <>
              <div className='mb-13 text-center'>
                <h1 className='mb-3'>Create Ticket</h1>
                <div className='text-gray-400 fw-bold fs-5'>
                  Hello {firstName} {lastName}. {REQUEST_SUPPORT_TITLE}
                </div>
              </div>
              <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
                <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                  <span className='required'>Subject</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Specify a subject for your issue'
                  ></i>
                </label>
                <Field
                  as='input'
                  name='subject'
                  className='form-control form-control-solid'
                  placeholder='Enter your ticket subject'
                  required
                />
                {touched.subject && errors.subject ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.subject}</div>
                  </div>
                ) : null}
              </div>
              <div className='row g-9 mb-8'>
                <div className='col-md-6 fv-row'>
                  <label className='required fs-6 fw-bold mb-2'>Product</label>
                  <SelectDropDown
                    data={products}
                    defaultValue='Select a product'
                    dirUp={false}
                    selectedValue={(value) => {
                      setFieldValue('product', value)
                    }}
                  />
                  <Field
                    as='input'
                    name='product'
                    className='form-control form-control-solid d-none'
                    placeholder='Enter your ticket subject'
                    required
                  />
                  {touched.product && errors.product ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{errors.product}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-bold mb-2'>Copy link this error occurred on:</label>
                <Field
                  as='input'
                  name='link'
                  className='form-control form-control-solid'
                  placeholder='Enter your link'
                  validate={validLink}
                />
                {touched.link && errors.link ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errors.link}</div>
                  </div>
                ) : null}
              </div>
              <div className='d-flex flex-column mb-8 fv-row fv-plugins-icon-container'>
                <label className='fs-6 mb-2 pt-1'>Description</label>
                <Field
                  as='textarea'
                  name='description'
                  className='form-control form-control-solid'
                  rows={4}
                  placeholder='Type your ticket description'
                />
              </div>
              <div className='fv-row mb-8'>
                <label className='fs-6 fw-bold mb-2'>Attachments</label>
                <div className='dropzone dz-clickable'>
                  {selectedFiles.length === 0 ? (
                    <div
                      className='dz-message needsclick align-items-center'
                      onClick={openInputFile}
                    >
                      <KTSVG
                        path='/media/icons/duotone/Files/Upload-file.svg'
                        className='svg-icon-3hx svg-icon-primary'
                      />
                      <div className='ms-4'>
                        <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                          Drop files here or click to upload.
                        </h3>
                        <span className='fw-bold fs-7 text-gray-400'>Upload up to 10 files</span>
                      </div>
                    </div>
                  ) : (
                    selectedFiles.map((file, index) => {
                      return (
                        <div key={index} className='dz-preview'>
                          <div className='dz-details'>
                            <div className='dz-size'>
                              <strong>0.7</strong> KB
                            </div>
                            <div className='dz-filename'>
                              <span>{file.name}</span>
                            </div>
                          </div>
                          <span onClick={() => handleCancelFile(file.name)} className='dz-remove'>
                            Remove file
                          </span>
                        </div>
                      )
                    })
                  )}
                  <input
                    type='file'
                    name='attachment[]'
                    className='form-control d-none'
                    ref={inputFileRef}
                    onChange={(event) => {
                      let files = event.currentTarget.files
                      let attachedFiles = [...allFiles, ...files]
                      let dt = new DataTransfer()

                      attachedFiles.forEach((file) => {
                        dt.items.add(file)
                      })

                      setFieldValue('attachment', dt.files)
                      setAllFiles(attachedFiles)

                      handleChangeFiles(files)
                    }}
                    multiple
                  />
                </div>
              </div>
              <div className='text-center pt-15'>
                <button type='button' className='btn btn-light me-3' onClick={onHide}>
                  Cancel
                </button>
                <button
                  type='submit'
                  className={`btn ${loading ? 'btn-light' : 'btn-primary'}`}
                  onClick={() => {
                    if (errors.link === '' && values.product !== '' && values.subject !== '') {
                      showConfirmAlert(CONFIRM_SUBMIT, handleSubmit)
                    } else {
                      handleSubmit()
                    }
                  }}
                >
                  {!loading ? (
                    <span className='indicator-label'>Submit</span>
                  ) : (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export { RequestSupport }
