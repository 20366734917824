import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../../types'
import { RequestSupport } from '../../../../app/components/_modal/RequestSupport'
import { ConfirmModal } from '../../../../app/components/_modal/ConfirmModal'
import { requestSupport } from '../../../../app/services/service'
import getContactEmail from './footerCrud'
import * as levelHelper from '../../../../helper/level.helper'
import { getErrorMessage } from '../../../../helper'
import toast from 'react-hot-toast'

const ContactLink = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [mailToEmail, setMailtoEmail] = useState<string>('mailto: jesse@threesdesign.net')
  const [isShow, setIsShow] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(false)
  const [confirmAlert, setConfirmAlert] = useState({
    show: false,
    content: '',
    onConfirm: () => {},
  })

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const showConfirmAlert = (content: string, onConfirm: any) => {
    setConfirmAlert({ show: true, content, onConfirm })
  }

  const handleSubmit = (values: any) => {
    setStatus(false)

    requestSupport(values)
      .then((res) => {
        toast.success(res.data)
        setIsShow(false)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
      .finally(() => {
        setStatus(true)
      })
  }

  useEffect(() => {
    getContactEmail()
      .then((res) => {
        setMailtoEmail(res.data)
      })
      .catch((res) => {
        setMailtoEmail('mailto: jesse@threesdesign.net')
      })

    setIsAdmin(levelHelper.isNBS(user?.type))
  }, [user])

  return (
    <>
      <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
        {isAdmin && (
          <li className='menu-item'>
            <a
              className='menu-link pe-0 pe-2'
              href='https://3sdesign.freshdesk.com/support/home'
              target='_blank'
            >
              Request Support
            </a>
          </li>
        )}
        <li className='menu-item'>
          <a
            href={isAdmin ? mailToEmail : '/contact-us'}
            id='mailToId'
            className='menu-link pe-0 pe-2'
          >
            Contact
          </a>
        </li>
      </ul>
      <RequestSupport
        submit={handleSubmit}
        status={status}
        show={isShow}
        onHide={() => setIsShow(false)}
        showConfirmAlert={showConfirmAlert}
      />
      {confirmAlert.show && (
        <ConfirmModal
          content={confirmAlert.content}
          yesText='Confirm'
          noText='Cancel'
          selectYes={() => {
            confirmAlert.onConfirm()
            setConfirmAlert({ ...confirmAlert, show: false })
          }}
          selectNo={() => setConfirmAlert({ ...confirmAlert, show: false })}
        />
      )}
    </>
  )
}

export default ContactLink
