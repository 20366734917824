/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'
import ContactLink from './footer/ContactLink'

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Creater Link */}
        <div className='text-dark order-2 order-md-1'>
          <a href='https://www.threesdesign.net/' className='text-gray-800 text-hover-primary' target="_blank">
            BY 3S Design LLC
          </a>
        </div>
        {/* end::Creater Link */}

        {/* begin::Contact Link */}
        <ContactLink />
        {/* end::Contact Link */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
