import axios from 'axios'

export const APP_URL = process.env.REACT_APP_API_URL

export async function requestServiceNow(payload: any) {
  const Service_URL = `${APP_URL}/service-now/request`

  return axios.post(Service_URL, { payload })
}

export async function requestContactEmail(payload: any) {
  const Service_URL = `${APP_URL}/contact-us`

  return axios.post(Service_URL, { payload })
}

export async function getCRFToken() {
  const Service_URL = `${APP_URL}/nbs-admins/token`

  return axios.get(Service_URL)
}

export function openNBSOnlinePdf(dbkey: number) {
  const API_URL = `${APP_URL}/get-view-url/${dbkey}`

  return axios.get(API_URL)
}

export function requestSupport(values: any) {
  const END_POINT = `${APP_URL}/request-support`
  let formData = new FormData()

  if (typeof values.attachment !== 'string') {
    for (let index = 0; index < values.attachment.length; index++) {
      formData.append(`attachment[${index}]`, values.attachment[index])
    }
  }

  Object.keys(values).forEach((key) => formData.append(key, values[key]))

  return axios.post(END_POINT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function sendNotification(payload: any) {
  const Service_URL = `${APP_URL}/send-notification`

  return axios.post(Service_URL, { payload })
}

export async function checkIsCustomerExist(customerID: any) {
  const Service_URL = `${APP_URL}/nbs-customers/check/`

  return axios.get(`${Service_URL}${customerID}`)
}

export async function checkIsBillingCompanyIdExist(dbkey: any, BillingCompanyID: any) {
  const Service_URL = `${APP_URL}/nbs-billing-company/check-company-id`

  return axios.post(`${Service_URL}`, { dbkey, BillingCompanyID })
}

export async function getCustomerById(customerID: string) {
  const Service_URL = `${APP_URL}/nbs-customers/get/`

  return axios.get(`${Service_URL}${customerID}`)
}

export async function getCustomersByOrderId(orderId: string | number) {
  const Service_URL = `${APP_URL}/customers/get/`

  return axios.get(`${Service_URL}${orderId}`)
}

export async function getEquipmentTypes() {
  const response: any = await axios.get(`${APP_URL}/equipment-types`)

  if (!response.data) return []

  return response.data
}

export async function getPaymentOptions(quoteId: number) {
  const Service_URL = `${APP_URL}/payment-option/get-profiles/${quoteId}`

  return axios.get(Service_URL)
}
